import { Box, Divider, FormControl, FormLabel, InputAdornment, InputLabel, MenuItem, Select, Switch, Typography } from '@mui/material';
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { Paper, IconButton, TextField } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import Colors from '../../../../theme/colors/Color';
import { Heading20Semi, Heading26Bold } from '../../../../common-components';
import 'react-quill/dist/quill.snow.css'; // import styles for react-quill
import ReactQuill from 'react-quill';
import { Search } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { FilledButton, OutlineButton } from '../../../../utils/component';
import { useNavigate } from 'react-router-dom';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
import { postData } from '../../../../services/APIService';

const CustomPaper = styled(Paper)(() => ({
  padding: '28px 9px',
}));
const CustomPaper1 = styled(Paper)(() => ({
  padding: '28px 9px',
  height: '350px',
}));

const toolbarOptions = [
  [{ font: [] }],
  [{ header: [1, 2, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ direction: 'rtl' }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ['link', 'image'],
  ['clean'],
  ['undo', 'redo'],
];

const AddCommunity = () => {
  const [value, setValue] = useState('');
  const [realmType, setRealmType] = useState('');
  const [communityData, setCommunityData] = useState({ community_name: '', description: '', realm: '', realm_select: '', user_type: '' });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Masters = useSelector((state) => state.masters);
  const userCategory = Masters?.data?.mstUserCategory || [];
  const segments = Masters?.data?.mstMarketSegment || [];
  const userData = JSON.parse(localStorage.getItem('userDetails'));

  const handleFirstSelectChange = (e) => {
    setRealmType(e.target.value);
    setCommunityData((prev) => ({ ...prev, realm: e.target.value, realm_select: '' }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateCommunityDetails()) return;
    try {
      const res = await postData('/community/addCommunity', communityData, {}, userData.token);
      if (res.data.status) {
        dispatch(showSnackbar({ message: 'Community created successfully', severity: 'success' }));
        navigate('/community');
      } else {
        dispatch(showSnackbar({ message: res.response?.data?.message, severity: 'error' }));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message ? error.response?.data?.message : error.message;
      dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
    }
  };
  const validateCommunityDetails = () => {
    if (!communityData.realm) {
      dispatch(showSnackbar({ message: 'Please select realm', severity: 'warning' }));
      return false;
    }
    if (communityData.realm && !communityData.realm_select) {
      dispatch(showSnackbar({ message: 'Please select realm select', severity: 'warning' }));
      return false;
    }
    if (!communityData.community_name) {
      dispatch(showSnackbar({ message: 'Please enter community name', severity: 'warning' }));
      return false;
    }
    if (!communityData.user_type) {
      dispatch(showSnackbar({ message: 'Please select user type', severity: 'warning' }));
      return false;
    }
    if (!communityData.description) {
      dispatch(showSnackbar({ message: 'Please enter description', severity: 'warning' }));
      return false;
    }
    return true;
  };

  return (
    <>
      <Heading26Bold text={'Create Community'} />
      <Box
        style={{
          backgroundColor: '#b3faff',
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: '20px',
          paddingRight: '10px',
          alignItems: 'center',
        }}
        sx={{
          height: {
            lg: '60px', // large screens
            xl: '7vh', // extra-large screens
          },
          width: {
            lg: '97%', // large screens
            xl: '98%', // extra-large screens
          },
        }}
      >
        <Typography
          sx={{
            fontSize: {
              lg: '14px',
              xl: '16px',
            },
          }}
        >
          <strong>Community Number:</strong>
        </Typography>
        <Typography
          sx={{
            fontSize: {
              lg: '14px',
              xl: '16px',
            },
          }}
        >
          <strong>Date Of Creation:</strong>
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'white',
            width: '50%',
            height: '30px',

            padding: {
              xs: '120px',
              lg: '5px',
              xl: '5px',
            },
          }}
        >
          <Typography
            style={{ fontWeight: 'bold' }}
            sx={{
              fontSize: {
                lg: '14px',
                xl: '16px',
              },
            }}
          >
            <span>
              <span style={{ color: '#9C9A9A' }}>Realm</span>
              <span style={{ color: 'red' }}> * </span>
            </span>
          </Typography>
          <select
            style={{
              minWidth: '170px',
              padding: '8px',
              fontSize: '14px',
              borderRadius: '4px',
              border: 'none',
            }}
            onChange={handleFirstSelectChange}
            defaultValue=""
          >
            <option value="" disabled>
              Select Realm
            </option>
            <option value="Segment">Segment</option>
            <option value="Category">Category</option>
          </select>
          <Typography
            style={{ margin: '4px', fontWeight: 'bold' }}
            sx={{
              fontSize: {
                lg: '14px',
                xl: '16px',
              },
            }}
          >
            <span>
              <span style={{ color: '#9C9A9A' }}>Select</span>
              <span style={{ color: 'red' }}> * </span>
              <span style={{ color: '#9C9A9A' }}> : </span>
            </span>
          </Typography>
          <select
            style={{
              minWidth: '220px',
              padding: '8px',
              fontSize: '14px',
              borderRadius: '4px',
              border: 'none',
            }}
            value={communityData.realm_select}
            onChange={(e) => setCommunityData((prev) => ({ ...prev, realm_select: e.target.value }))}
            disabled={!realmType} // Disable when no selection in the first dropdown
          >
            <option value="" disabled selected>
              {realmType === 'Segment' ? 'Select Segment' : realmType === 'Category' ? 'Select Category' : 'Select'}
            </option>

            {/* Conditionally render options based on the selected value */}
            {realmType === 'Segment' && (
              <>
                {segments.map((category) => {
                  return (
                    <option key={category.id} value={category.name}>
                      {category.name}
                    </option>
                  );
                })}
              </>
            )}
            {realmType === 'Category' && (
              <>
                {userCategory.map((category) => (
                  <option key={category.id} value={category.name}>
                    {category.name}
                  </option>
                ))}
                {/* <option value="trader">Trader</option>
                <option value="manufacturer">Manufacturer</option>
                <option value="oem">OEM</option> */}
              </>
            )}
          </select>
        </Box>
      </Box>
      <Box sx={{ marginTop: '30px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8}>
            <CustomPaper elevation={5} sx={{ borderRadius: '10px', height: { md: '42vh', xs: '40vh' }, padding: '10px 9px 45px' }}>
              <FormLabel>
                <Heading20Semi text={'Post Messages '} />
              </FormLabel>
              <TextField variant="standard" placeholder="Add A Subject" size="small" fullWidth sx={{ marginTop: '10px' }} disabled />
              <Box mt={1}>
                <Box
                  sx={{
                    height: {
                      xs: '120px', // small screens
                      lg: '128px', // large screens
                      xl: '24vh', // extra-large screens
                    },
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    borderRadius: '4px',
                    overflow: 'hidden', // Ensure the content fits within the box
                  }}
                >
                  <ReactQuill
                    fullWidth
                    value={value}
                    onChange={setValue}
                    placeholder="Type A Message"
                    backgroundColor="red"
                    modules={{ toolbar: toolbarOptions }}
                    style={{ height: '100%' }}
                    readOnly={true}
                  />
                </Box>
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    top: '10px',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Divider
                    sx={{
                      width: '100%',
                      borderBottom: '2px solid',
                      borderColor: Colors.primary,
                      height: {
                        xl: '5vh',
                      },
                    }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <IconButton component="span" sx={{ fontWeight: 'bold', pointerEvents: 'none' }}>
                      <AttachFileIcon sx={{ fontWeight: 'bold', color: Colors.text_black }} />
                    </IconButton>
                    <IconButton sx={{ color: Colors.primary, pointerEvents: 'none' }}>
                      <SendIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </CustomPaper>
          </Grid>

          <Grid item xs={12} lgm={4}>
            <CustomPaper
              elevation={5}
              sx={{ borderRadius: '10px', height: { md: '42vh' }, padding: '10px 9px 45px', marginBottom: '15px' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <FormLabel>
                  <Heading20Semi text={'Community Details '} />
                </FormLabel>
                <Switch size="medium" disabled={true} />
              </Box>
              <Box sx={{ marginTop: 2 }}>
                <TextField
                  sx={{
                    height: { md: '5vh' },
                    '& .MuiInputBase-root': {
                      height: { xl: '10vh' },
                      alignItems: 'flex-start',
                    },
                  }}
                  label={
                    <span>
                      <span style={{ color: 'black' }}>Name Of Community</span>
                      <span style={{ color: 'red' }}> *</span>
                    </span>
                  }
                  placeholder="Name of Community"
                  variant="outlined"
                  fullWidth
                  size="small"
                  margin="dense"
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  value={communityData.community_name}
                  onChange={(e) => setCommunityData((prev) => ({ ...prev, community_name: e.target.value }))}
                />
              </Box>

              <FormControl
                fullWidth
                margin="dense"
                variant="outlined"
                size="small"
                sx={{
                  mt: 2,
                  height: { md: '5vh' },
                  '& .MuiInputBase-root': {
                    height: { lg: '7vh' },
                    alignItems: 'flex-start',
                  },
                }}
              >
                <InputLabel style={{ color: 'black' }}>
                  <span>
                    <span style={{ color: 'black' }}>User Type</span>
                    <span style={{ color: 'red' }}> *</span>
                  </span>
                </InputLabel>
                <Select
                  label="User Type"
                  value={communityData.user_type}
                  onChange={(e) => setCommunityData((prev) => ({ ...prev, user_type: e.target.value }))}
                >
                  <MenuItem value="Supplier">Supplier</MenuItem>
                  <MenuItem value="Customer">Customer</MenuItem>
                </Select>
              </FormControl>
              <TextField
                sx={{
                  mt: 2,
                  '& .MuiInputBase-root': {
                    height: { lg: '17vh', xl: '20vh' },
                    alignItems: 'flex-start',
                  },
                }}
                fullWidth
                label={
                  <span>
                    <span style={{ color: 'black' }}>Community Description</span>
                    <span style={{ color: 'red' }}> *</span>
                  </span>
                }
                placeholder="Community Description"
                variant="outlined"
                multiline
                rows={3}
                size="small"
                margin="dense"
                value={communityData.description}
                onChange={(e) => setCommunityData((prev) => ({ ...prev, description: e.target.value }))}
              />
            </CustomPaper>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} lg={8}>
            <CustomPaper1 elevation={5}>
              <FormControl>
                <FormLabel>
                  <Heading20Semi text="Previous Messages" />
                </FormLabel>
              </FormControl>
              <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                <Typography variant="body1" align="center" style={{ marginTop: '10px' }}>
                  No previous messages to show
                </Typography>
              </Box>
            </CustomPaper1>
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomPaper1 elevation={5}>
              <FormControl>
                <FormLabel>
                  <Heading20Semi text="Add/Remove Members" />
                </FormLabel>
              </FormControl>
              <Box sx={{ marginTop: 2 }}>
                <label>Add New Member</label>
                <TextField placeholder="Combo" variant="outlined" size="small" fullWidth style={{ marginTop: '10px' }} disabled />
              </Box>
              <Box sx={{ marginTop: 2 }}>
                <label>List Of member</label>
                <TextField
                  placeholder="Search Members"
                  variant="outlined"
                  size="small"
                  fullWidth
                  style={{ marginTop: '10px' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  disabled
                />
              </Box>
              <Box display="flex" justifyContent="center" style={{ height: '100%' }}>
                <Typography variant="body1" align="center" style={{ marginTop: '30px' }}>
                  No Members added
                </Typography>
              </Box>
            </CustomPaper1>
          </Grid>
        </Grid>
        <Box
          width={{ xs: '65vw', sm: '30vw' }}
          sx={{
            margin: '0px auto',
            marginTop: '30px',
            display: 'flex',
            gap: '25px',
          }}
        >
          <OutlineButton text={'Cancel'} onClick={() => navigate('/community')} />
          <FilledButton text={'Submit'} onClick={handleSubmit} />
        </Box>
      </Box>
    </>
  );
};

export default AddCommunity;
