import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getData } from '../../../../services/APIService';
// import { Heading26Bold } from '../../../../utils/component';
import Colors from '../../../../theme/colors/Color';
import { Box, InputAdornment, styled, TextField, Typography } from '@mui/material';
import Pagination from '../../../../utils/component/pagination/Pagination';
import { Magnifier } from '../../../../theme/Images';

const headerCellStyle = {
  border: '1px solid #ddd',
  color: '#fff',
  backgroundColor: '#0093D3',
  fontFamily: 'poppins-regular',
  textTransform: 'capitalize',
};
const limit = 50;
let totalPages = 1;
const CustomSearch = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '&.MuiInputBase-root': {
      height: '40px',
      borderRadius: '10px',
      width: '31.8vw',
      background: Colors.white,
    },
  },
}));

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const formattedDate = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });

  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  return `${formattedDate}, ${formattedTime}`;
};

const TallyInvetory = () => {
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const [list, setList] = useState([]);
  const [page, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const fetchTallyFinancials = async () => {
    await getData(`/tally/tally_inventory?page=${page}&limit=${limit}&keyword=${keyword}`, {}, userData.token)
      .then((res) => {
        if (res.data.data && res.data.data?.result) {
          setList(res.data.data?.result || []);
          totalPages = res.data.data?.totalPages || 1;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchTallyFinancials();
  }, [page, keyword]);
  const handleSearch = (e) => {
    const keyword = e.target.value.trim();
    setKeyword(keyword);
    setCurrentPage(1);
  };

  const dateFormat = (dateString) => {
    const dateObject = new Date(dateString);
    const day = dateObject.getUTCDate();
    const month = dateObject.getUTCMonth() + 1;
    const year = dateObject.getUTCFullYear();
    const formattedDay = day.toString().padStart(2, '0');
    const formattedMonth = month.toString().padStart(2, '0');

    return `${formattedDay}/${formattedMonth}/${year}`;
  };

  return (
    <>
      {/* <Heading26Bold text="Tally Inventory Master" /> */}
      <Box
        sx={{
          marginTop: '20px',
          height: '10.4vh',
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: ' center',
          padding: '0px 30px',
          background: Colors.bg_strip,
        }}
      >
        <CustomSearch
          placeholder="Search Tally Inventory"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={Magnifier} alt="Search" style={{ width: '20px', height: '20px' }} />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        {list && list.length > 0 && <Typography>Last Updated :{formatDate(list[0]?.updatedAt ?? list[0]?.createdAt)}</Typography>}
      </Box>
      <TableContainer component={Paper} sx={{ marginTop: 0 }}>
        <Table sx={{ minWidth: 650, border: '1px solid #ddd' }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#0093D3' }}>
              <TableCell sx={headerCellStyle}>Product code</TableCell>
              <TableCell sx={headerCellStyle}>Heat No</TableCell>
              <TableCell sx={headerCellStyle}>Supplier Code</TableCell>
              <TableCell sx={headerCellStyle}>Supplier GST no.</TableCell>
              <TableCell sx={headerCellStyle}>Name of Supplier</TableCell>
              <TableCell sx={headerCellStyle}>Wearhouse location </TableCell>
              <TableCell sx={headerCellStyle}>Opening stock</TableCell>
              <TableCell sx={headerCellStyle}>Inward stock</TableCell>
              <TableCell sx={headerCellStyle}>Outward</TableCell>
              <TableCell sx={headerCellStyle}>Tally/total stock=opening + inward-outward</TableCell>
              <TableCell sx={headerCellStyle}>Export Date</TableCell>
              {/* <TableCell sx={{ border: '1px solid #ddd', color: '#fff' }}>Status</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#fff' }}>Blocked stock</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#fff' }}>Sold</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#fff' }}>Display Stock</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#fff' }}>Validation</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ color: row.product_id ? '' : 'red', border: '1px solid #ddd' }} component="th" scope="row">
                  {row.product_code}
                </TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.heat_number}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.supplier_code}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.supplier_gst}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.supplier_name}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.warehouse_location}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.opening_stock}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.inward_stock}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.outward_stock}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.total_stock}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{dateFormat(row.export_date)}</TableCell>
                {/* <TableCell sx={{ border: '1px solid #ddd' }}>{row.status}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.blockStock}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.sold}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.displayStock}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.validation}</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination currentPage={page} totalPages={totalPages} onPageChange={(p) => setCurrentPage(p)} />
    </>
  );
};

export default TallyInvetory;
