import React, { useEffect, useState, useRef } from 'react';
import { Box, IconButton, Modal, TextField, Typography, Tooltip, Autocomplete, Chip, Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { styled } from '@mui/material/styles';
import './AddProducts.css';
import { CircleLoader } from 'react-spinners';
import { RefreshCw, Trash2, X } from 'react-feather';
import Colors from '../../../../theme/colors/Color';
import { useAddProducts } from '../AddProducts/useAddProducts';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { NumList } from '../../../../utils/constants/dropdownList';
import { statusConstant } from '../../../../utils/constants/status';
import {
  Combobox,
  CustomRadioBtn,
  FilledButton,
  Heading16Bold,
  Heading16Reg,
  Heading20Semi,
  Heading26Bold,
  InputTextField,
  OutlineButton,
} from '../../../../utils/component';
import { useDispatch, useSelector } from 'react-redux';
import { getData, postData, postFileData } from '../../../../services/APIService';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import Comment from '../../../../utils/component/comment/Comment';
import QuantityChangeHistory from './QuantityChangeHistory';
import { BOS_GST } from '../../../../utils/constants/common';
const userData = JSON.parse(localStorage.getItem('userDetails'));

const CustomPaper = styled(Paper)(() => ({
  padding: '28px 9px',
}));

const FieldBox = styled(Box)(() => ({
  height: '11.5vh',
  marginBottom: '2.5px',
}));

const SmallTextField = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    height: '45px',
    width: '180%',
  },
  '& .MuiInputBase-input': {
    padding: '10px 12px',
  },
}));

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: '#00C9D84D',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  marginTop: theme.spacing(2),
}));

const InfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(0),
  },
}));

const RowBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: theme.spacing(0),
}));

const initData = {
  pc_id: '',
  spc_id: '',
  product_description: '',
  additional_info: '',
  grade: '',
  shape: '',
  size: '',
  mill: '',
  processRoute: '',
  heatTreat: '',
  surfaceCondition: '',
  heat_number: '',
  test_certificate: false,
  location: '',
  quantity: '',
  moq: '',
  recommended_usage: '',
  firm_id: '',
  basic_price: '',
  status: 'Active',
  show_in_catalog: true,
  is_bos_stock: false,
  date_of_retire: null,
  reason_to_retire: '',
  createdAt: new Date().toLocaleDateString(),
};

const AddProducts = () => {
  const PM_Masters = useSelector((state) => state.PM_Masters);
  const [formData, setFormData] = useState(initData);
  const [fileName, setFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [product, setProduct] = useState(initData);
  const [subProductCategoryList, setSubProductCategoryList] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [suppliersList, setSuppliersList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [productImgs, setProductImgs] = useState([]);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isProductExist, setIsProductExist] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const isBos = (state && state.supplier && state.supplier == 'BOS') || false;
  const editMode = state && state.id ? true : false;
  const viewMode = state && state.mode === 'view';
  const sourceRef = useRef(null);
  const targetRef = useRef(null);
  let sourceHeight = 0;
  useEffect(() => {
    if (sourceRef.current && targetRef.current) {
      sourceHeight = sourceRef.current.offsetHeight;
      targetRef.current.style.height = `${sourceHeight}px`;
    }
  }, []);
  const setpData = (p) => {
    const imgArr = p.attachments
      .map((d) => {
        if (d.document_type.startsWith('image/') && d.url) {
          return { id: d.attachment_id, src: d.url, type: 'image' };
        }
        return null;
      })
      .filter((item) => item !== null);

    const pdfDocument = p.attachments.find((d) => d.document_type === 'application/pdf' && d.url);
    if (pdfDocument) setIsFileUploaded(true);
    setFileName(pdfDocument ? pdfDocument.url.split('/').pop().split('_')[2] : '');
    setProductImgs(imgArr);
    setProduct({
      product_id: state.id,
      product_code: p.product_code,
      product_name: p.product_name,
      category_name: p.category.category_name,
      spc_id: p.subCategory.sub_category_name,
      product_description: p.product_description,
      additional_info: p.additional_info,
      grade: p.grade,
      shape: p.shape,
      size: p.size,
      mill: p.mill,
      processRoute: p.processRoute,
      heatTreat: p.heatTreat,
      surfaceCondition: p.surfaceCondition,
      heat_number: p.heat_number,
      test_certificate: p.test_certificate,
      location: p.addressLocation?.city,
      quantity: p.quantity,
      inventory: p?.inventory[0],
      display_stock: p?.display_stock,
      booked_quantity: p?.booked_quantity,
      sold_quantity: p?.sold_quantity,
      moq: p.moq,
      recommended_usage: p.recommended_usage,
      firm: p.firm.id,
      basic_price: p.basic_price,
      status: p.status,
      show_in_catalog: p.show_in_catalog,
      is_bos_stock: p.is_bos_stock || '',
      date_of_retire: p.date_of_retire ? new Date(p.date_of_retire).toISOString().split('T')[0] : '',
      reason_to_retire: p.reason_to_retire || '',
      createdAt: new Date(p.createdAt).toLocaleDateString(),
      updatedAt: new Date(p.updatedAt).toLocaleDateString(),
      createdBy: p.creator?.name,
    });
    setFormData({
      product_id: state.id,
      product_code: p.product_code,
      product_name: p.product_name,
      pc_id: p.category.pc_id,
      spc_id: p.subCategory.spc_id,
      product_description: p.product_description,
      additional_info: p.additional_info,
      grade: p.grade.grade_id,
      shape: p.shape.shape_id,
      size: p.size.size_id,
      mill: p.mill.mill_id,
      processRoute: p.processRoute.pr_id,
      heatTreat: p.heatTreat.ht_id,
      surfaceCondition: p.surfaceCondition.sc_id,
      heat_number: p.heat_number,
      test_certificate: p.test_certificate,
      location: p.addressLocation?.address_id || '',
      quantity: p.quantity,
      moq: p.moq,
      recommended_usage: p.recommended_usage,
      supplier_id: p.supplier?.user_id,
      firm_id: p.firm.id,
      basic_price: p.basic_price,
      status: p.status,
      show_in_catalog: p.show_in_catalog,
      is_bos_stock: p.is_bos_stock ?? isBos ?? false,
      date_of_retire: p.date_of_retire ? new Date(p.date_of_retire).toISOString().split('T')[0] : null,
      reason_to_retire: p.reason_to_retire || '',
      createdAt: new Date(p.createdAt).toLocaleDateString(),
      updatedAt: new Date(p.updatedAt).toLocaleDateString(),
      createdBy: p.creator?.name,
      price: p.price,
    });
  };
  const {
    isLoading,
    error,
    uploadedImages,
    getRootProps,
    getInputProps,
    handleDeleteImage,
    open,
    selectedImage,
    handleOpen,
    handleClose,
    handleReplaceClick,
    handleReplaceChange,
    replaceImageInputRef,
    deletedIds,
  } = useAddProducts(productImgs);
  useEffect(() => {
    fetchSupplier();
    if (state && state.id) fetchProduct();
  }, []);
  useEffect(() => {
    if (formData?.pc_id) fetchSubProductSubCategory();
  }, [formData.pc_id]);
  useEffect(() => {
    if (formData?.spc_id) fetchSize();
  }, [formData.spc_id]);
  useEffect(() => {
    if (formData.firm_id) getLocationBySupplier();
  }, [formData.firm_id]);

  useEffect(() => {
    if (
      formData.pc_id &&
      formData.spc_id &&
      formData.product_description &&
      formData.grade &&
      formData.shape &&
      formData.size &&
      formData.mill &&
      formData.processRoute &&
      formData.heatTreat &&
      formData.surfaceCondition &&
      formData.heat_number &&
      formData.location &&
      formData.moq &&
      formData.firm_id &&
      formData.basic_price &&
      formData.status &&
      uploadedImages &&
      uploadedImages.length > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [formData, uploadedImages]);

  const fetchProduct = async () => {
    try {
      const res = await getData(`/product/${state.id}/${userData?.user?.user_id}`, {}, userData.token);
      if (res && res.data && res.data.status) {
        setpData(res.data.data);
      } else dispatch(showSnackbar({ message: '"Something went wrong"', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
  };
  const fetchSupplier = async () => {
    try {
      const res = await getData('/firm/all?type=SUPPLIER', {}, userData.token);
      if (res && res.data && res.data.status) {
        let data = res.data.data;
        if (!editMode) {
          if (isBos) {
            data = res.data.data.filter((d) => d.gst_no === BOS_GST);
            setFormData((prev) => ({ ...prev, firm_id: data[0]?.id }));
          } else data = res.data.data.filter((d) => d.gst_no != BOS_GST);
        }
        setSuppliersList(data);
      } else dispatch(showSnackbar({ message: '"Something went wrong"', severity: 'error' }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSubProductSubCategory = async () => {
    try {
      const res = await getData(`/mstSubProductCategories/getByCategoryId?product_category_id=${formData.pc_id}`);
      if (res && res.data && res.data.status) {
        setSubProductCategoryList(res.data.data);
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSize = async () => {
    try {
      const res = await getData('/mstSizes/getAllmstSizesv1', { spc_id: formData.spc_id });
      if (res && res.data && res.data.status) {
        setSizeList(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getLocationBySupplier = async () => {
    try {
      const res = await getData(`/address/byfirm/${formData.firm_id}`, {}, userData.token);
      if (res && res.data && res.data.status) {
        const addressList = res.data.data.map((addr) => ({
          id: addr.address_id,
          label: addr.address_line_1 + ', ' + addr.address_line_2 + ', ' + addr.city + ', ' + addr.state,
        }));
        setAddressList(addressList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveProducts = async () => {
    try {
      if (!selectedFile) formData.test_certificate = false;
      const res = await postData(`/product/addProduct`, formData, {}, userData.token);
      if (res && res.data && res.data.data && res.data.data.product_id) {
        const productId = res.data.data.product_id;
        saveProductImg(productId);
        navigate('/product');
      } else {
        dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
      }
    } catch (error) {
      console.error('Error in saveProducts function:', error);
      if (error.status === 409) setIsProductExist(true);
      else if (error.response && error.response.data) {
        dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      } else {
        dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
      }
    }
  };

  const updateProducts = async () => {
    try {
      if (!selectedFile) formData.test_certificate = false;
      saveProductImg(state.id);
      const res = await postData('/product/update', formData, {}, userData.token);
      if (res && res.data && res.data.status) {
        dispatch(showSnackbar({ message: 'Product updated successfully', severity: 'success' }));
        navigate(-1);
      } else dispatch(showSnackbar({ message: '"Something went wrong"', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
  };

  const saveProductImg = async (productId) => {
    try {
      const imageFormData = new FormData();
      const newImages = uploadedImages.filter((item) => !item.id);
      newImages.forEach((image, index) => {
        if (image) {
          imageFormData.append(`image${index + 1}`, image.file);
        }
      });
      if (selectedFile) {
        imageFormData.append('pdf', selectedFile);
      }
      imageFormData.append('module_attachment_id', productId);
      imageFormData.append('type', 'Product');
      imageFormData.append('attachment_name', 'product_image');
      postData(`/image/deleteUploadedImage`, { attachment_id: deletedIds }, {}, userData.token);
      postFileData(`/image/uploadImages`, imageFormData, {}, userData.token);
    } catch (error) {
      console.error('Error in saveProductImg function:', error);
    }
  };

  const handleProductCategory = (e) => {
    if (e) {
      setFormData((prev) => ({ ...prev, pc_id: e.pc_id, size: null }));
      setProduct((prev) => ({ ...prev, category_name: e.category_name, size: {} }));
    }
  };
  const handleSupplierChange = (_, v) => {
    if (v) {
      setFormData((prev) => ({ ...prev, firm_id: v.id }));
      setProduct((prev) => ({ ...prev, firm: v.id }));
    }
  };
  const handleSubProductCategory = (e) => {
    if (e) {
      setFormData((prev) => ({ ...prev, spc_id: e.spc_id, size: null }));
      setProduct((prev) => ({ ...prev, size: {} }));
    }
  };
  const handleGrade = (e) => {
    if (e) {
      setFormData((prev) => ({ ...prev, grade: e.grade_id }));
      setProduct((prev) => ({ ...prev, grade: e }));
    }
  };
  const handleshape = (e) => {
    if (e) {
      setFormData((prev) => ({ ...prev, shape: e.shape_id }));
      setProduct((prev) => ({ ...prev, shape: e }));
    }
  };
  const handleProcessRoute = (e) => {
    if (e) {
      setFormData((prev) => ({ ...prev, processRoute: e.pr_id }));
      setProduct((prev) => ({ ...prev, processRoute: e }));
    }
  };
  const handleHeatTeat = (e) => {
    if (e) {
      setFormData((prev) => ({ ...prev, heatTreat: e.ht_id }));
      setProduct((prev) => ({ ...prev, heatTreat: e }));
    }
  };
  const handleSurfaceCondition = (e) => {
    if (e) {
      setFormData((prev) => ({ ...prev, surfaceCondition: e.sc_id }));
      setProduct((prev) => ({ ...prev, surfaceCondition: e }));
    }
  };
  const handleSize = (e) => {
    if (e) {
      setFormData((prev) => ({ ...prev, size: e.size_id }));
      setProduct((prev) => ({ ...prev, size: e }));
    }
  };
  const handleMill = (e) => {
    if (e) {
      setFormData((prev) => ({ ...prev, mill: e.mill_id }));
      setProduct((prev) => ({ ...prev, mill: e }));
    }
  };
  const handleLocation = (e) => {
    if (e) {
      setFormData((prev) => ({ ...prev, location: e.id }));
      setProduct((prev) => ({ ...prev, location: e }));
    }
  };
  const handleMinQty = (e) => {
    if (e) {
      setFormData((prev) => ({ ...prev, moq: e.label }));
    }
  };
  const handleText = (e) => {
    if (e) {
      setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };
  const handleRadio = (e) => {
    e && setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value == 'yes' }));
  };
  const handleTestCertificate = (e) => {
    e && setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value == 'yes' }));
    if (e && e.target.value === 'no') {
      {
        setFileName('');
        setSelectedFile(null);
        document.getElementById('upload-pdf').value = '';
      }
    }
  };

  const handleStatus = (e) => {
    if (e && e.target.value === 'Retired') {
      setModalOpen(true);
    } else setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setSelectedFile(file);
    }
  };

  const handleModalConfirm = () => {
    setModalOpen(false);
    setFormData((prev) => ({ ...prev, status: 'Retired' }));
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Box>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <Typography variant="h5" component="h1" sx={{ flexGrow: 1, typography: { xs: 'h6', sm: 'h5' } }}>
            <Heading26Bold text={(viewMode ? 'View' : editMode ? 'Edit' : 'Add') + ' Product'} />
          </Typography>
        </Box>
        <Container>
          <InfoBox>
            <Typography variant="body1" sx={{ typography: { xs: 'body2', sm: 'body1' } }}>
              <strong>Product Name: </strong>
              {formData.product_name}
            </Typography>
            <Typography variant="body1" sx={{ typography: { xs: 'body2', sm: 'body1' } }}>
              <strong>Date Of Entry: </strong>
              {formData.createdAt}
            </Typography>
          </InfoBox>
          <InfoBox>
            <Typography variant="body1" sx={{ typography: { xs: 'body2', sm: 'body1' } }}>
              <strong>Product Code: </strong>
              {formData.product_code}
            </Typography>
            <Typography variant="body1" sx={{ typography: { xs: 'body2', sm: 'body1' } }}>
              <strong>Last Edited: </strong>
              {formData.updatedAt}
            </Typography>
          </InfoBox>
          <InfoBox>
            <RowBox>
              <Typography variant="body1" sx={{ typography: { xs: 'body2', sm: 'body1' }, marginRight: 2 }}>
                <strong>Supplier’s Name:</strong>
              </Typography>
              <Autocomplete
                sx={{
                  width: 200,
                  '& .MuiOutlinedInput-root': {
                    height: 32,
                    '& .MuiInputBase-input': {
                      padding: '0',
                      marginTop: '-2px',
                      fontSize: '0.875rem',
                    },
                    '& fieldset': {
                      border: 'none',
                    },
                  },
                }}
                value={
                  isBos
                    ? suppliersList.find((option) => option.gst_no === BOS_GST) || null
                    : suppliersList.find((option) => option.id === product.firm) || null
                }
                options={suppliersList}
                getOptionLabel={(option) => option.firm_name}
                disabled={isBos || editMode}
                onChange={handleSupplierChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </RowBox>
            <Typography variant="body1" sx={{ typography: { xs: 'body2', sm: 'body1' } }}>
              <strong>Entered By User : </strong>
              {formData.createdBy || userData?.user?.name}
            </Typography>
            <Typography></Typography>
          </InfoBox>
        </Container>
      </Box>
      <Grid container spacing={2} marginTop={0}>
        <Grid ref={sourceRef} item xs={12} lgm={8}>
          <CustomPaper elevation={5} sx={{ borderRadius: '10px' }}>
            <FormControl>
              <FormLabel>
                <Heading20Semi text={'Product Details '} />
              </FormLabel>
            </FormControl>
            <Grid container gap={{ xs: 2, md: 0 }} sx={{ marginTop: '15px' }}>
              <Grid item xs={12} md={4} paddingRight={{ xs: 0, md: '5px' }}>
                <FieldBox>
                  <Combobox
                    label={'Product Category'}
                    optionsLabel={'category_name'}
                    options={PM_Masters.productCategories}
                    isDisable={editMode}
                    isRequire={false}
                    value={product.category_name}
                    onChangeValue={handleProductCategory}
                  />
                </FieldBox>

                <FieldBox>
                  <Combobox
                    isExternal={product.grade.is_external}
                    label={'Grade'}
                    optionsLabel={'grade_name'}
                    options={PM_Masters.grades}
                    isDisable={editMode}
                    isRequire={false}
                    value={product.grade.grade_name}
                    onChangeValue={handleGrade}
                  />
                </FieldBox>
                <FieldBox>
                  <Combobox
                    isExternal={product.heatTreat.is_external}
                    label={'Heat Treat'}
                    optionsLabel={'heat_treat_name'}
                    options={PM_Masters.heatTreats}
                    isDisable={editMode}
                    isRequire={false}
                    value={product.heatTreat.heat_treat_name}
                    onChangeValue={handleHeatTeat}
                  />
                </FieldBox>
                <FieldBox>
                  <Combobox
                    isExternal={product.mill.is_external}
                    label={'Mill Name'}
                    optionsLabel={'mill_name'}
                    options={PM_Masters.mills}
                    isDisable={editMode}
                    isRequire={false}
                    value={product.mill.mill_name}
                    onChangeValue={handleMill}
                  />
                </FieldBox>
                <FieldBox>
                  <FormControl>
                    <FormLabel>
                      <Heading16Reg text={'Test Certificate'} isDisable={viewMode || isFileUploaded} />
                    </FormLabel>
                    <RadioGroup
                      row
                      name="test_certificate"
                      value={formData.test_certificate ? 'yes' : formData.test_certificate === false ? 'no' : ''}
                      onChange={handleTestCertificate}
                    >
                      <FormControlLabel disabled={viewMode || isFileUploaded} value="yes" control={<CustomRadioBtn />} label="Yes" />
                      <FormControlLabel disabled={viewMode || isFileUploaded} value="no" control={<CustomRadioBtn />} label="No" />
                    </RadioGroup>
                  </FormControl>
                  <Box mt={3} sx={{ fontWeight: 'bold' }}>
                    <Heading16Bold text={'Availability and Location'} />
                  </Box>
                </FieldBox>
              </Grid>
              <Grid item xs={12} md={4} paddingRight={{ xs: 0, md: '5px' }}>
                <FieldBox>
                  <Combobox
                    label={'Product Subcategory'}
                    optionsLabel={'sub_category_name'}
                    options={subProductCategoryList}
                    isDisable={!formData.pc_id || editMode}
                    isRequire={false}
                    name="spc_id"
                    value={subProductCategoryList.find((option) => option.spc_id === formData.spc_id)?.sub_category_name || null}
                    onChangeValue={handleSubProductCategory}
                  />
                </FieldBox>
                <FieldBox>
                  <Combobox
                    isExternal={product.shape.is_external}
                    label={'Shape'}
                    optionsLabel={'shape_name'}
                    options={PM_Masters.shapes}
                    isDisable={editMode}
                    isRequire={false}
                    value={product.shape.shape_name}
                    onChangeValue={handleshape}
                  />
                </FieldBox>
                <FieldBox>
                  <Combobox
                    isExternal={product.surfaceCondition.is_external}
                    label={'Surface Condition'}
                    optionsLabel={'surface_condition'}
                    options={PM_Masters.surfaceConditions}
                    isDisable={editMode}
                    isRequire={false}
                    value={product.surfaceCondition.surface_condition}
                    onChangeValue={handleSurfaceCondition}
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label="Heat No."
                    name="heat_number"
                    placeholder="Heat No."
                    variant="outlined"
                    fullWidth
                    isDisable={editMode}
                    value={formData.heat_number}
                    onChange={handleText}
                  />
                </FieldBox>
                <div>
                  <SmallTextField
                    item
                    xs={6}
                    label="Upload Test Certificate"
                    name="UploadTestCertificate"
                    placeholder="Upload Test Certificate"
                    variant="outlined"
                    fullWidth
                    value={fileName}
                    disabled={!formData.test_certificate || isFileUploaded}
                    InputProps={{
                      startAdornment: (
                        <AttachFileIcon
                          onClick={() => fileInputRef.current.click()}
                          style={!formData.test_certificate || isFileUploaded ? {} : { cursor: 'pointer' }}
                        />
                      ),
                    }}
                  />
                  <input
                    type="file"
                    id="upload-pdf"
                    accept="application/pdf"
                    ref={fileInputRef}
                    disabled={!formData.test_certificate || isFileUploaded}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4} paddingLeft={{ xs: 0, md: '3px' }}>
                <FieldBox ml={5} mt={-0.6}>
                  <FormControl>
                    <Box>
                      <FormLabel sx={{ displayFlex: 'flex', justifyContent: 'center' }}>
                        <Heading16Reg isDisable={viewMode} text={'Show in Catalogue'} />
                      </FormLabel>
                    </Box>
                    <RadioGroup
                      row
                      name="show_in_catalog"
                      value={formData.show_in_catalog ? 'yes' : formData.show_in_catalog === false ? 'no' : ''}
                      onChange={handleRadio}
                    >
                      <FormControlLabel disabled={viewMode} value="yes" control={<CustomRadioBtn />} label="Yes" />
                      <FormControlLabel disabled={viewMode} value="no" control={<CustomRadioBtn />} label="No" />
                    </RadioGroup>
                  </FormControl>
                </FieldBox>
                <FieldBox mt={1}>
                  <Combobox
                    isExternal={product.processRoute.is_external}
                    label={'Process Route'}
                    optionsLabel={'pr_name'}
                    options={PM_Masters.processRoutes}
                    isDisable={editMode}
                    isRequire={false}
                    value={product.processRoute.pr_name}
                    onChangeValue={handleProcessRoute}
                  />
                </FieldBox>
                <FieldBox>
                  <Combobox
                    isExternal={product.size.is_external}
                    label={'Size of Product'}
                    optionsLabel={'size_name'}
                    options={sizeList}
                    isDisable={!formData.spc_id || editMode}
                    isRequire={false}
                    value={product.size.size_name}
                    onChangeValue={handleSize}
                  />
                </FieldBox>
              </Grid>
              <Grid item xs={12} md={4} mt={5} paddingRight={{ xs: 0, md: '5px' }}>
                <FieldBox>
                  <Combobox
                    label={'Available at BOS Warehouse (Location)'}
                    optionsLabel={'label'}
                    options={addressList}
                    isDisable={!formData.firm_id || editMode}
                    isRequire={false}
                    value={addressList.find((option) => option.id === formData.location)?.label || null}
                    onChangeValue={handleLocation}
                  />
                  <Typography sx={{ color: Colors.error, fontSize: '9px', display: 'inline' }}>
                    (At least One Location is mandatory for a Product to be Active)
                  </Typography>
                </FieldBox>

                <FieldBox sx={{ marginTop: '25px' }}>
                  <InputTextField
                    isDisable={editMode}
                    label={'Offered Price'}
                    name={'basic_price'}
                    placeholder={'50'}
                    isRequire={true}
                    value={formData.basic_price}
                    onChange={handleText}
                  />
                </FieldBox>
              </Grid>
              <Grid item xs={12} md={4} mt={5} paddingRight={{ xs: 0, md: '5px' }}>
                <FieldBox>
                  <InputTextField
                    isDisable={
                      viewMode ||
                      (editMode &&
                        suppliersList
                          .find((option) => option.id === product.firm)
                          ?.firm_name?.toLowerCase()
                          .includes('blue ocean steels'))
                    }
                    label={'Quantity Available'}
                    name={'quantity'}
                    placeholder={'100'}
                    isRequire={false}
                    value={formData.quantity}
                    onChange={handleText}
                  />
                </FieldBox>
                <FieldBox sx={{ mt: 3 }}>
                  <InputTextField
                    isDisable={true}
                    label={'Final Price'}
                    name={'price'}
                    isRequire={false}
                    value={formData.price?.length > 0 ? formData.price[0].base_price : '0'}
                  />
                </FieldBox>
              </Grid>
              <Grid item xs={12} md={4} mt={5} paddingLeft={{ xs: 0, md: '5px' }}>
                <FieldBox>
                  <Combobox
                    label={'Minimum Order Quantity'}
                    optionsLabel={'label'}
                    name={'moq'}
                    options={NumList}
                    isDisable={viewMode}
                    isRequire={false}
                    value={NumList.find((option) => option.label === formData.moq)?.value || null}
                    onChangeValue={handleMinQty}
                  />
                </FieldBox>
              </Grid>
            </Grid>
          </CustomPaper>
          <Grid item xs={12} lgm={12} sx={{ marginTop: '30px' }}>
            <CustomPaper
              elevation={5}
              sx={{
                borderRadius: '10px',
                height: {
                  lg: '20vh',
                  xl: '24vh',
                },
              }}
            >
              <Grid container gap={{ xs: 2, md: 0 }}>
                <Grid item xs={12} md={12} paddingRight={{ xs: 0, md: '5px' }}>
                  <FormControl>
                    <FormLabel>
                      <Heading20Semi text={'BOS Administrator '} />
                    </FormLabel>
                  </FormControl>
                  <FieldBox>
                    <FormControl>
                      <FormLabel>
                        <Heading16Reg isDisable={viewMode} text={'Product Status'} />
                      </FormLabel>
                      <RadioGroup row name="status" value={formData.status} onChange={handleStatus}>
                        <FormControlLabel
                          disabled={viewMode || formData.status === 'Retired'}
                          value={statusConstant.active}
                          control={<CustomRadioBtn />}
                          label={statusConstant.active}
                        />
                        <FormControlLabel
                          disabled={viewMode || formData.status === 'Retired'}
                          value={statusConstant.inactive}
                          control={<CustomRadioBtn />}
                          label={statusConstant.inactive}
                        />
                        <FormControlLabel
                          disabled={viewMode || formData.status === 'Retired'}
                          value={statusConstant.retired}
                          control={<CustomRadioBtn />}
                          label={statusConstant.retired}
                        />
                      </RadioGroup>
                    </FormControl>
                  </FieldBox>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputTextField
                          isDisable={editMode}
                          label={'Reason to Retire'}
                          name={'reason_to_retire'}
                          placeholder={'Reason to Retire'}
                          isRequire={false}
                          value={formData.reason_to_retire}
                          onChange={handleText}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputTextField
                          isDisable={editMode}
                          label={'Date of Retire'}
                          name={'date_of_retire'}
                          placeholder={''}
                          isRequire={false}
                          type="date"
                          value={formData.date_of_retire || ' '}
                          onChange={handleText}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CustomPaper>
          </Grid>
        </Grid>

        <Grid ref={targetRef} sx={{ display: 'flex', flexDirection: 'column' }} item xs={12} lgm={4}>
          {editMode && (
            <Box
              sx={{
                backgroundColor: Colors.left_pannel,
                p: 1,
                borderRadius: 2,
                maxWidth: 800,
                height: 'auto',
                marginBottom: 2,
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography gutterBottom sx={{ fontSize: 15, fontWeight: 'bold' }}>
                  Product Code:
                </Typography>
                <Typography gutterBottom sx={{ fontSize: 15, fontWeight: 'bold' }}>
                  {formData.product_code}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Box>
                  <Typography variant="body2" gutterBottom sx={{ color: Colors.form_lines, fontSize: '12px', mt: -1.0 }}>
                    Date of Entry: {formData.createdAt}
                  </Typography>
                  <Typography variant="body2" gutterBottom sx={{ color: Colors.form_lines, fontSize: '12px' }}>
                    Warehouse Location: {product.location}
                  </Typography>
                </Box>
                <Box sx={{ textAlign: 'right' }}>
                  <Typography variant="body2" sx={{ color: Colors.form_lines, fontSize: '12px', textAlign: 'right', mt: -1.0 }}>
                    Last Edited: {formData.updatedAt}
                  </Typography>
                  <Typography variant="body2" sx={{ color: Colors.form_lines, fontSize: '12px', textAlign: 'right', mt: 0.5 }}>
                    Show in Catalogue
                    <Chip
                      label={formData.show_in_catalog == true ? 'Yes' : 'No'}
                      sx={{
                        marginLeft: 1,
                        color: Colors.white,
                        height: '20px',
                        bgcolor: formData.show_in_catalog === true ? Colors.success : Colors.error,
                      }}
                    />
                  </Typography>
                </Box>
              </Box>

              <Grid container spacing={2} textAlign="center">
                <Grid item xs={6} sm={3}>
                  <Typography variant="body1" sx={{ fontSize: '14px', color: Colors.primary, fontWeight: 'bold' }}>
                    {product?.quantity} MT.
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '9.5px', fontWeight: 'bold' }}>
                    Total Quantity
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography variant="body1" sx={{ fontSize: '14px', color: Colors.primary, fontWeight: 'bold' }}>
                    {product?.booked_quantity} MT.
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '9.5px', fontWeight: 'bold' }}>
                    Quantity Booked
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography variant="body1" sx={{ fontSize: '14px', color: Colors.primary, fontWeight: 'bold' }}>
                    {product?.sold_quantity} MT.
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '9.5px', fontWeight: 'bold' }}>
                    Quantity Sold
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography variant="body1" sx={{ fontSize: '14px', color: Colors.primary, fontWeight: 'bold' }}>
                    {product?.display_stock} MT.
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '9.4px', fontWeight: 'bold' }}>
                    Quantity Balance
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          <Grid sx={{ flex: '1 1 auto', margin: 0, padding: 0 }} item xs={12} lgm={12} elevation={5}>
            <CustomPaper
              elevation={5}
              sx={() => ({
                borderRadius: '10px',
                height: '100%',
                py: 0,
              })}
            >
              <Box py={2}>
                <FormControl>
                  <FormLabel>
                    <Heading20Semi text="Product Images" />
                  </FormLabel>
                </FormControl>
                <Grid container spacing={0.5} mt={'5px'}>
                  {viewMode && uploadedImages.length < 5 ? (
                    <></>
                  ) : (
                    <Grid item xs={12} sm={6} md={6}>
                      {uploadedImages.length < 5 ? (
                        <Box
                          {...getRootProps()}
                          sx={{
                            borderRadius: '10px',
                            height: { xs: 80, sm: 90 },
                            width: '70%',
                            maxWidth: '400px',
                            bgcolor: '#D9D9D9',
                            border: '2px dotted #0458AB',
                            cursor: 'pointer',
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <input {...getInputProps()} />
                          {isLoading ? (
                            <div className="loader-container">
                              <CircleLoader size={50} color="#123abc" loading={true} />
                            </div>
                          ) : (
                            <Typography align="center" sx={{ typography: { xs: 'body2', sm: 'body2' } }}>
                              Drag and drop your image here{' '}
                              <span style={{ color: '#0458AB', textDecoration: 'underline' }}>Click to browse</span>. Max. 5MB File Size
                            </Typography>
                          )}
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            borderRadius: '10px',
                            height: { xs: 150, sm: 120, md: 115 },
                            width: { xs: '100%', sm: '80%', md: '80%' },
                            maxWidth: '400px',
                            bgcolor: '#D9D9D9',
                            cursor: 'pointer',
                            overflow: 'hidden',
                            position: 'relative',
                            p: 2,
                            '& img': {
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            },
                            '& > div': {
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              display: 'flex',
                            },
                            '& .trash-icon': {
                              width: 10,
                              height: 10,
                              bgcolor: 'lightgray',
                              borderRadius: '50%',
                              p: 1,
                              mr: 2,
                              transition: 'background-color 0.3s ease',
                            },
                            '&:hover': {
                              transform: 'scale(1.5)',
                              color: 'red',
                            },
                          }}
                        >
                          <Tooltip title="Click to show full image" arrow>
                            <img
                              src={uploadedImages[4].src}
                              alt="Uploaded 5"
                              className="zoom-image"
                              onClick={() => handleOpen(uploadedImages[4].src)}
                            />
                          </Tooltip>
                          {!viewMode && (
                            <div>
                              <Tooltip title="Click to replace image" arrow>
                                <RefreshCw className="trash-icon" onClick={() => handleReplaceClick(4)} style={{ color: 'black' }} />
                              </Tooltip>
                              <Tooltip title="Click to delete image" arrow>
                                <Trash2 className="trash-icon" onClick={() => handleDeleteImage(4)} />
                              </Tooltip>
                            </div>
                          )}
                        </Box>
                      )}
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={6}>
                    <Grid container spacing={1}>
                      {uploadedImages.slice(0, 4).map((image, index) => (
                        <Grid item xs={6} sm={6} key={index}>
                          <Box
                            sx={{
                              borderRadius: '7px',
                              border: '0.5px solid lightgray',
                              height: { xs: 80, sm: 60, md: 50 },
                              width: { xs: '100%', sm: '95%', md: '90%' },
                              bgcolor: 'white',
                              cursor: 'pointer',
                              overflow: 'hidden',
                              position: 'relative',
                              p: 1,
                              '& img': {
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                              },
                              '& > div': {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex',
                              },
                              '& .trash-icon': {
                                width: 10,
                                height: 10,
                                bgcolor: 'lightgray',
                                borderRadius: '50%',
                                p: 1,
                                mr: 1,
                                transition: 'background-color 0.3s ease',
                              },
                              '&:hover': {
                                transform: 'scale(2)',
                                color: 'red',
                              },
                            }}
                          >
                            <Tooltip title="Click to show full image" arrow>
                              <img src={image.src} alt={`Uploaded ${index}`} className="zoom-image" onClick={() => handleOpen(image.src)} />
                            </Tooltip>
                            {!viewMode && (
                              <Box>
                                <Tooltip title="Click to replace image" arrow>
                                  <RefreshCw className="trash-icon" onClick={() => handleReplaceClick(index)} style={{ color: 'black' }} />
                                </Tooltip>
                                <Tooltip title="Click to delete image" arrow>
                                  <Trash2 className="trash-icon" onClick={() => handleDeleteImage(index)} />
                                </Tooltip>
                              </Box>
                            )}
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  {error && (
                    <Box className="error-message">
                      <Typography>{error}</Typography>
                    </Box>
                  )}
                </Grid>

                <input type="file" ref={replaceImageInputRef} style={{ display: 'none' }} onChange={handleReplaceChange} />
                <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: 'auto',
                      bgcolor: 'background.paper',
                      border: '2px solid #000',
                      boxShadow: 24,
                      p: 4,
                    }}
                  >
                    <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleClose}>
                      <X />
                    </IconButton>
                    <img src={selectedImage} alt="Full view" style={{ width: '100%', height: 'auto' }} />
                  </Box>
                </Modal>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} lgm={12} mt={3}>
                  <FormLabel sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                    <Heading16Reg text="Product Description" />
                    <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
                  </FormLabel>
                  <Box height="auto">
                    <TextField
                      variant="outlined"
                      placeholder="Product Related"
                      name="product_description"
                      disabled={viewMode}
                      value={formData.product_description}
                      multiline
                      rows={8}
                      sx={{
                        width: '100%',
                      }}
                      onChange={handleText}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} lgm={12} mt={2}>
                <FormLabel sx={{ p: 1, display: 'flex' }}>
                  <Heading16Reg text="Additional Information" />
                </FormLabel>
                <Box height="auto">
                  <TextField
                    variant="outlined"
                    placeholder="Product Related"
                    name="additional_info"
                    disabled={viewMode}
                    value={formData.additional_info}
                    multiline
                    rows={5}
                    sx={{
                      width: '100%',
                    }}
                    onChange={handleText}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} lgm={12} mt={2}>
                <FormLabel sx={{ p: 1, display: 'flex' }}>
                  <Heading16Reg text="Recommended Usage" />
                </FormLabel>
                <Box height="auto">
                  <TextField
                    variant="outlined"
                    placeholder="Product Related"
                    name="recommended_usage"
                    disabled={viewMode}
                    value={formData.recommended_usage}
                    multiline
                    rows={2.5}
                    sx={{
                      width: '100%',
                    }}
                    onChange={handleText}
                  />
                </Box>
              </Grid>
            </CustomPaper>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: { xs: '65vw', sm: '30vw' },
          margin: '0px auto',
          marginTop: '30px',
          display: 'flex',
          gap: '25px',
        }}
      >
        {!viewMode && (
          <>
            {state && state.id ? (
              <FilledButton isdisable={isDisabled} text={'Update'} onClick={updateProducts} />
            ) : (
              <FilledButton isdisable={isDisabled} text={'Save'} onClick={saveProducts} />
            )}
          </>
        )}
        <OutlineButton text={'Cancel'} onClick={() => navigate(-1)} />
      </Box>
      {editMode && (
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12} lgm={8}>
            <Comment module_id={formData.product_id} comment_type={'product'} />
          </Grid>
          <Grid item xs={12} lgm={4}>
            <QuantityChangeHistory product_id={formData.product_id} />
          </Grid>
        </Grid>
      )}

      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: 'none',
            boxShadow: 24,
            p: 4,
            borderRadius: '2px',
            textAlign: 'center',
          }}
        >
          <CancelIcon
            sx={{
              fontSize: 50,
              color: Colors.error,
              borderStyle: 'solid',
              borderRadius: '50%',
              borderWidth: 6,
              borderColor: '#E5B3B3',
            }}
          />
          <Typography sx={{ color: Colors.error, fontWeight: 600, mb: 2 }}>ERROR</Typography>
          <Typography sx={{}}> Are you sure you want to retire the product</Typography>
          <Typography sx={{}}> The status cannot be reversed to Active/Inactive.</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mx: 7 }}>
            <Button
              variant="contained"
              onClick={handleModalConfirm}
              sx={{ textTransform: 'none', borderRadius: '20px', bgcolor: Colors.primary, width: '130px' }}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              onClick={handleModalClose}
              sx={{ textTransform: 'none', borderRadius: '20px', width: '130px', color: Colors.primary }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={isProductExist} onClose={() => setIsProductExist(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 180,
            bgcolor: 'background.paper',
            border: 'none',
            boxShadow: 24,
            p: 5,
            borderRadius: '20px',
            textAlign: 'center',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              cursor: 'pointer',
            }}
            onClick={() => setIsProductExist(false)}
          >
            <CloseIcon />
          </Box>
          <Typography sx={{ mt: 2 }}>
            Product with same details already exists, please change details to add a new product or you can modify the existing product
            using &apos;Edit&apos; option.
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default AddProducts;
