import React, { useEffect, useMemo, useState } from 'react';
// import { Heading20Semi } from '../../../../utils/component';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TextField,
  styled,
  TableCell,
  InputAdornment,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
// import CategoryMaster from './categoryMaster';
import { postData } from '../../../../services/APIService';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
import { useDispatch } from 'react-redux';
import Pagination from '../../../../utils/component/pagination/Pagination';
import Colors from '../../../../theme/colors/Color.js';
import { Magnifier } from '../../../../theme/Images';
const headerCellStyle2 = {
  border: '1px solid #0093D3',
  fontFamily: 'poppins-regular',
};
const CustomSearch = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '&.MuiInputBase-root': {
      height: '40px',
      borderRadius: '10px',
      width: '31.8vw',
      background: Colors.white,
    },
  },
}));
const ProductMaster = () => {
  const hideAddDeleteForMaster = 'Product Code Master';
  const productMasterList = useMemo(
    () => [
      {
        name: 'Product Code Master',
        apiBase: '/product',
        getAllRoute: '/list',
        addRoute: '/savemstProductCategories',
        updateRoute: '/updatemstProductCategories',
        deleteRoute: '/bulkDelete',
        idKey: 'product_id',
        columnList: [
          { heading: 'Product Code', field: 'product_code' },
          { heading: 'Grade', field: 'grade.grade_name' },
          { heading: 'Grade Code', field: 'grade.grade_code' },
          { heading: 'Shape', field: 'shape.shape_name' },
          { heading: 'Shape Code', field: 'shape.shape_code' },
          { heading: 'Process Route', field: 'processRoute.pr_name' },
          { heading: 'Process Route Code', field: 'processRoute.pr_code' },
          { heading: 'Heat Treat', field: 'heatTreat.heat_treat_name' },
          { heading: 'Heat Treat Code', field: 'heatTreat.ht_code' },
          { heading: 'Surface Condition', field: 'surfaceCondition.surface_condition' },
          { heading: 'Surface Condition Code', field: 'surfaceCondition.sc_code' },
          { heading: 'Size', field: 'size.size_name' },
          { heading: 'Size Code', field: 'size.size_code' },
          { heading: 'Mill', field: 'mill.mill_name' },
          { heading: 'Mill Code', field: 'mill.mill_code' },
        ],
        fields: [{ type: 'text', name: 'category_name' }],
      },
    ],
    []
  );

  const limit = 10;
  const selectedMaster = productMasterList[0];
  const [masterDataList, setMasterDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [keyword, setKeyword] = useState();

  const dispatch = useDispatch();
  // const userData = JSON.parse(localStorage.getItem('userDetails'));
  useEffect(() => {
    fetchMasterList();
  }, [currentPage, keyword]);
  const fetchMasterList = async () => {
    try {
      const urlPath = `${selectedMaster.apiBase + selectedMaster.getAllRoute}`;
      const res = await postData(urlPath, { page: currentPage, limit, filters: {}, keyword });
      if (res?.data?.data) {
        setTotalPages(res.data.data.totalPages);
        const dataList = res.data.data?.data ? res.data.data.data : res.data.data?.products ? res.data.data.products : [];
        setMasterDataList(dataList);
      } else {
        setMasterDataList([]);
        dispatch(showSnackbar({ message: res.response.data.message, severity: 'error' }));
      }
    } catch (error) {
      console.log('error: ', error);
      const errorMessage = error.response?.data?.message ? error.response?.data?.message : error.message;
      dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
      setMasterDataList([]);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const getNestedFieldValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };
  const handleSearch = (e) => {
    const keyword = e.target.value.trim();
    setKeyword(keyword);
    setCurrentPage(1);
  };
  return (
    <>
      {/* <Heading20Semi text="Product Master" /> */}
      <Box
        sx={{
          marginTop: '20px',
          height: '10.4vh',
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: ' center',
          padding: '0px 30px',
          background: Colors.bg_strip,
        }}
      >
        <CustomSearch
          placeholder="Search Product Code"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={Magnifier} alt="Search" style={{ width: '20px', height: '20px' }} />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        <Box
          sx={{
            marginTop: '20px',
            height: '10.4vh',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: ' center',
            gap: '20px',
          }}
        ></Box>
      </Box>
      <Box sx={{ mt: 1 }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" sx={{ minWidth: 650, border: '1px solid #0093D3' }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#0093D3' }} key={'rowHeading'}>
                {selectedMaster.columnList.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{ border: '1px solid #ffffff', fontWeight: 600, textTransform: 'capitalize', color: '#ffffff' }}
                  >
                    {column.heading}
                  </TableCell>
                ))}
                {selectedMaster.name !== hideAddDeleteForMaster && (
                  <TableCell key={'edit'} sx={{ border: '1px solid #0093D3', fontWeight: 600, color: '#ffffff' }}>
                    Edit
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {masterDataList.map((row) => (
                <TableRow key={row[selectedMaster.idKey]} sx={{ backgroundColor: row?.is_external == true ? '#F4C7C9' : 'none' }}>
                  {selectedMaster.columnList.map((column) => (
                    <TableCell key={column.field} sx={headerCellStyle2}>
                      {getNestedFieldValue(row, column.field) || ''}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      </Box>
    </>
  );
};

export default ProductMaster;
