import {
  Autocomplete,
  Box,
  Button,
  // Checkbox,
  CircularProgress,
  FormControl,
  Modal,
  // styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  // TextField,
  Typography,
  styled,
  InputAdornment,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import { postData } from '../../../../services/APIService';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
// import { Heading20Semi } from '../../../../utils/component';
import Pagination from '../../../../utils/component/pagination/Pagination.jsx';
import { Edit } from '../../../../theme/Images.js';
import { fetchCTPOMaster } from '../../../../redux/features/masters/CT_PO_MasterSlice.js';
import Colors from '../../../../theme/colors/Color.js';
import { Magnifier } from '../../../../theme/Images';

const headerCellStyle1 = {
  border: '1px solid #ddd',
  fontWeight: 600,
  color: '#ffffff',
};
const headerCellStyle2 = {
  border: '1px solid #0093D3',
  fontFamily: 'poppins-regular',
};
// const CustomBtnBox = styled(Box)(() => ({
//   height: '35px',
//   width: '35px',
//   borderRadius: '6px',
//   padding: '10px',
//   background: '#70FACB4D',
//   textAlign: 'center',
//   cursor: 'pointer',
//   marginBottom: 20,
// }));
const CustomSearch = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '&.MuiInputBase-root': {
      height: '40px',
      borderRadius: '10px',
      width: '31.8vw',
      background: Colors.white,
    },
  },
}));

let totalPages = 1;
const limit = 10;

export function PriceMaster() {
  const initFormData = {
    price_id: '',
    product_id: '',
    credit_term_id: '',
    payment_option_id: '',
    base_price: '',
  };
  const CT_PO_Masters = useSelector((state) => state.CT_PO_Masters);
  const [price, setPrice] = useState([]);
  const [open, setOpen] = useState(false);
  const [dynamicHeadings, setDynamicHeadings] = useState([]);
  const [creditTermList, setCreditTermsList] = useState([]);
  const [paymentOptionList, setPaymentOptionList] = useState([]);
  const [isPaymentOptionDisable, setIsPaymentOptionDisable] = useState(false);
  const [formData, setFormData] = useState(initFormData);
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [productCode, setProductCode] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState();

  const fetchPriceMasterData = async () => {
    await postData(`/mstPrice/get`, { page: currentPage, limit, keyword })
      .then((res) => {
        if (res.data.data) {
          setDynamicHeadings(res.data.data?.creditsPaymentsHeaders || []);
          setPrice(res.data.data?.paymentTerms || []);
          totalPages = res.data.data?.totalPages || 1;
        }
      })
      .catch((error) => {
        console.error(error);
        setPrice([]);
        setDynamicHeadings([]);
        dispatch(showSnackbar({ message: error?.response?.data?.message || error.message, severity: 'error' }));
      });
  };

  useEffect(() => {
    fetchPriceMasterData();
  }, [currentPage, keyword]);

  useEffect(() => {
    dispatch(fetchCTPOMaster());
  }, []);

  const handleClose = () => {
    setFormData(initFormData);
    // setPriceData(initFormData);
    setOpen(false);
    setIsEdit(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const edit = async (item) => {
    setOpen(true);
    setCreditTermsList(CT_PO_Masters?.creditTerms || []);
    setPaymentOptionList(CT_PO_Masters?.paymentOptions || []);
    setFormData((prevData) => ({ ...prevData, product_id: item.product_id }));
    setProductCode(item?.product_code || '');
    setIsEdit(true);
    setIsPaymentOptionDisable(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (formData.price_id) await postData('/mstPrice/edit', formData, {}, userData.token);
      else await postData('/mstPrice/add', formData, {}, userData.token);
      dispatch(showSnackbar({ message: `Price ${formData.price_id ? 'updated' : 'added'} successfully`, severity: 'success' }));
      fetchPriceMasterData();
      handleClose();
    } catch (error) {
      dispatch(showSnackbar({ message: error?.response?.data?.message || error.message, severity: 'error' }));
    } finally {
      setLoading(false);
    }

    setFormData(initFormData);
  };
  const getPriceData = async () => {
    try {
      const response = await postData('/mstPrice/getPriceData', formData, {}, userData.token);
      setFormData(response.data.data);
    } catch (error) {
      // dispatch(showSnackbar({ message: error?.response?.data?.message || error.message, severity: 'error' }));
      setFormData((prev) => ({ ...prev, ['base_price']: formData.base_price || '' }));
    }
  };
  useEffect(() => {
    if (formData.payment_option_id && formData.product_id && formData.credit_term_id) {
      getPriceData();
    }
  }, [formData.product_id, formData.payment_option_id, formData.credit_term_id]);
  const handleSelectCreditTerm = async (e) => {
    if (e) {
      let paypemt_option = '';
      let paymentOption = '';
      paymentOption = paymentOptionList.find((item) => item.payment_option == 'Advance');
      if (e.credit_term == '0') {
        paypemt_option = paymentOption?.id;
      }
      if (e.credit_term == '7') {
        paymentOption = paymentOptionList.find((item) => item.payment_option == 'PDC');
        paypemt_option = paymentOption?.id;
      }
      setFormData((prevData) => ({
        ...prevData,
        ['credit_term_id']: e.id,
        ['base_price']: '',
        ['payment_option_id']: paypemt_option || (formData.payment_option_id == paymentOption?.id ? '' : formData.payment_option_id),
      }));
      setIsPaymentOptionDisable(paypemt_option ? true : false);
    }
  };
  const handleSelectPaymentOption = async (item) => {
    setFormData((prevData) => ({
      ...prevData,
      ['base_price']: '',
      ['payment_option_id']: item.id,
    }));
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  // const handleSearch = () => {};
  const handleSearch = (e) => {
    const keyword = e.target.value.trim();
    setKeyword(keyword);
    setCurrentPage(1);
  };
  return (
    <div>
      {/* <Heading20Semi text="Price Master" /> */}
      <Box
        sx={{
          marginTop: '20px',
          height: '10.4vh',
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: ' center',
          padding: '0px 30px',
          background: Colors.bg_strip,
        }}
      >
        <CustomSearch
          placeholder="Search Product Code"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={Magnifier} alt="Search" style={{ width: '20px', height: '20px' }} />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        <Box
          sx={{
            marginTop: '20px',
            height: '10.4vh',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: ' center',
            gap: '20px',
          }}
        ></Box>
      </Box>
      <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'flex-end' }}></Box>
      <Box sx={{ mt: 1 }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" sx={{ minWidth: 650, border: '1px solid #ddd' }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={6} sx={headerCellStyle2}></TableCell>
                {dynamicHeadings.map((value, index) => (
                  <TableCell key={index} sx={{ border: '1px solid #0093D3', color: 'red' }}>
                    {value == 'Advance' ? 'Advance Payment' : value}
                  </TableCell>
                ))}
                <TableCell sx={headerCellStyle2}></TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: '#0093D3' }}>
                <TableCell sx={headerCellStyle1}>Product Category</TableCell>
                <TableCell sx={headerCellStyle1}>Sub Category</TableCell>
                <TableCell sx={headerCellStyle1}>Product Code</TableCell>
                <TableCell sx={headerCellStyle1}>Heat No</TableCell>
                <TableCell sx={headerCellStyle1}>Supplier Code</TableCell>
                <TableCell sx={headerCellStyle1}>Warehose Location</TableCell>
                <TableCell sx={headerCellStyle1}>Price-Rs./MT </TableCell>
                {dynamicHeadings.map((value, index) => (
                  <TableCell key={index} sx={{ border: '1px solid #ddd' }}></TableCell>
                ))}
                {/* <TableCell></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {price.map((row, index) => (
                <TableRow key={row.product_id}>
                  <TableCell sx={headerCellStyle2}>{row?.category || ''}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row?.sub_category || ''}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row?.product_code || ''}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row?.heat_number || ''}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row?.supplier_code || ''}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row?.warehouse_loction || ''}</TableCell>
                  {dynamicHeadings.map((heading, index) => {
                    const creditPayment = row.credit_payments?.find((payment) => payment.credit_payment === heading);
                    return (
                      <TableCell key={index} sx={headerCellStyle2}>
                        {creditPayment ? creditPayment.base_price : '-'}
                      </TableCell>
                    );
                  })}
                  <TableCell sx={headerCellStyle2}>
                    <img
                      key={'Edit' + index}
                      style={{ height: '25px', marginLeft: '10px', marginTop: '5px', cursor: 'pointer' }}
                      src={Edit}
                      alt="edit"
                      onClick={() => edit(row)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      </Box>

      {/* Modal for adding new freight rates */}
      <Modal open={open} onClose={handleClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Paper sx={{ padding: 2, width: 400 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>{isEdit ? 'Edit' : 'Add New'} Master Price</Typography>
          <form onSubmit={handleSubmit}>
            <FormControl sx={{ width: '100%' }}>
              <TextField
                label="Product Code"
                name="product_code"
                value={productCode || ''}
                onChange={(e) => {
                  const { value } = e.target;
                  if (!isNaN(value)) {
                    handleChange(e);
                  }
                }}
                fullWidth
                margin="normal"
                required
                disabled={isEdit}
                sx={{
                  '& .MuiInputLabel-asterisk': {
                    color: 'red',
                  },
                }}
              />
            </FormControl>
            <FormControl sx={{ mt: 2, width: '100%' }}>
              <Autocomplete
                id="credit-terms-search"
                options={creditTermList}
                getOptionLabel={(option) => option.credit_term || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span>
                        Select Credit Terms <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    variant="outlined"
                  />
                )}
                value={creditTermList.find((item) => item.id === formData.credit_term_id) || null}
                onChange={(event, value) => {
                  if (value) {
                    handleSelectCreditTerm(value);
                  }
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                fullWidth
              />
            </FormControl>
            <FormControl sx={{ mt: 2, width: '100%' }}>
              <Autocomplete
                id="payment-option-search"
                options={paymentOptionList}
                getOptionLabel={(option) => option.payment_option || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span>
                        Select Payment Option <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    variant="outlined"
                  />
                )}
                value={paymentOptionList.find((item) => item.id === formData.payment_option_id) || null}
                onChange={(event, value) => {
                  if (value) {
                    console.log('value: ', value);
                    handleSelectPaymentOption(value);
                  }
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                fullWidth
                disabled={isPaymentOptionDisable}
                getOptionDisabled={(option) =>
                  formData.credit_term_id &&
                  creditTermList.find((item) => item.id === formData.credit_term_id)?.credit_term !== '0' &&
                  option.payment_option === 'Advance'
                }
                required
              />
            </FormControl>
            <FormControl sx={{ width: '100%' }}>
              <TextField
                label="Price"
                name="base_price"
                value={formData.base_price}
                onChange={(e) => {
                  const { value } = e.target;
                  if (!isNaN(value)) {
                    handleChange(e);
                  }
                }}
                fullWidth
                margin="normal"
                required
                sx={{
                  '& .MuiInputLabel-asterisk': {
                    color: 'red',
                  },
                }}
              />
            </FormControl>

            <Button type="submit" variant="contained" sx={{ mt: 2 }} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Submit'}
            </Button>
            <Button variant="outlined" sx={{ mt: 2, ml: 2 }} onClick={handleClose}>
              Cancel
            </Button>
          </form>
        </Paper>
      </Modal>
    </div>
  );
}
