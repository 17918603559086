import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, Pagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getData } from '../../../services/APIService';
import { enquiryManagement, OrderManagemeht, productManagement, communityIcon } from '../../../theme/Images';
import { fetchNotificationCount } from '../../../redux/features/notification/notificationSlice';
import { useDispatch } from 'react-redux';

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [selectedTab, setSelectedTab] = useState('All');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const pageSize = 5;
  const userData = JSON.parse(localStorage.getItem('userDetails')) || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (userData?.token) fetchNotifications();
  }, [selectedTab, page]);

  const fetchNotifications = async () => {
    let url = `/notification?page=${page}&pageSize=${pageSize}`;
    if (selectedTab === 'New') url += '&isRead=false';
    if (selectedTab === 'Read') url += '&isRead=true';

    try {
      const res = await getData(url, {}, userData?.token);
      if (res && res.data && res.status) {
        let data = res.data.notifications.map((item) => {
          let icon;
          if (item.payload.type == 'enquiry') icon = enquiryManagement;
          if (item.payload.type == 'order') icon = OrderManagemeht;
          if (item.payload.type == 'product') icon = productManagement;
          if (item.payload.type == 'community') icon = communityIcon;

          const localTime = new Date(item.createdAt).toLocaleString('en-US', {
            timeZone: clientTimeZone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          });
          const updatedBody = item.body.replace(/(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z)/g, localTime);

          return { ...item, icon, updatedBody };
        });
        setNotifications(data);
        setTotalPages(res.data.totalPages);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const handleMarkAsRead = async (id) => {
    try {
      const res = await getData(`/notification/${id}/read`, {}, userData?.token);
      if (res && res.status) {
        fetchNotifications();
        dispatch(fetchNotificationCount(userData?.token));
      }
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    setPage(1);
  };

  const handleNotificationClick = (type, id) => {
    if (type === 'enquiry') navigate(`/enquiry/edit/${id}`, { state: { mode: 'edit' } });
    else if (type == 'product') navigate('/product/add', { state: { id, mode: 'view' } });
    else if (type == 'order') navigate('/order/details', { state: { id, mode: 'view' } });
    else if (type == 'community') navigate('/community/edit/' + id, { state: { id, mode: 'edit' } });
    else navigate(`/${type}/${id}`);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box padding="5px">
      <Typography variant="h5" fontWeight="bold" marginBottom="20px">
        Notifications
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
        {['All', 'New', 'Read'].map((tab, index) => (
          <Box
            key={index}
            onClick={() => handleTabChange(tab)}
            sx={{
              width: '155px',
              height: '45px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              borderRadius: '20px ',
              backgroundColor: selectedTab === tab ? '#00C9D84D' : '#C1BEBE',
              transition: 'background-color 0.3s ease',
              border: selectedTab === tab ? '1px solid #70FACB4D' : '1px solid transparent',
              marginRight: index !== 2 ? '10px' : '0',
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontFamily: 'poppins-regular', opacity: selectedTab === index ? 1 : 0.5, fontWeight: 'bold' }}
            >
              {tab}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box display="flex" flexDirection="column" gap="15px">
        {notifications.length > 0 ? (
          notifications.map((notification) => (
            <Card
              key={notification.id}
              sx={{
                backgroundColor: notification.read ? '#FFF' : '#00C9D81A',
                borderLeft: notification.read ? '3px solid green' : '5px solid orange',
                cursor: 'pointer',
                transition: 'transform 0.2s ease',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
              onClick={() => {
                handleMarkAsRead(notification.id);
                handleNotificationClick(notification.payload.type, notification.payload.id);
              }}
            >
              <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box
                  sx={{
                    width: 40,
                    height: 40,
                    backgroundColor: '#f5f5f5',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '5px',
                    overflow: 'hidden',
                  }}
                >
                  <img src={notification.icon} alt="Notification Icon" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                </Box>

                <Box flex={1}>
                  <Typography variant="h6" fontWeight="bold">
                    {notification.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" marginBottom="10px">
                    {notification.updatedBody}
                  </Typography>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="caption" color="text.secondary">
                      {new Date(notification.createdAt).toLocaleString()}
                    </Typography>
                    {!notification.read && (
                      <Typography variant="caption" color="orange" fontWeight="bold">
                        New
                      </Typography>
                    )}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          ))
        ) : (
          <Typography variant="body1">No notifications available.</Typography>
        )}
      </Box>

      {totalPages > 1 && (
        <Box marginTop="20px" display="flex" justifyContent="center">
          <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
        </Box>
      )}
    </Box>
  );
};

export default Notification;
