import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Heading20Semi } from '../../../../utils/component';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
// import CategoryMaster from './categoryMaster';
import { getData, postData, postFileData } from '../../../../services/APIService';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
import { useDispatch } from 'react-redux';
import Pagination from '../../../../utils/component/pagination/Pagination';
import { Add, attachmentIcon, Delete, Edit } from '../../../../theme/Images';
import Colors from '../../../../theme/colors/Color';
import CloseIcon from '@mui/icons-material/Close';

const headerCellStyle2 = {
  border: '1px solid #0093D3',
  fontFamily: 'poppins-regular',
};
const CustomBtnBox = styled(Box)(() => ({
  height: '35px',
  width: '35px',
  borderRadius: '6px',
  padding: '10px',
  background: '#70FACB4D',
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: 10,
}));
const DimensionsMaster = () => {
  const productMasterList = useMemo(
    () => [
      {
        name: 'Category',
        apiBase: '/mstProductCategories',
        getAllRoute: '/getAllmstProductCategories',
        addRoute: '/savemstProductCategories',
        updateRoute: '/updatemstProductCategories',
        deleteRoute: '/bulkDelete',
        idKey: 'pc_id',
        columnList: [
          { heading: 'Category Name', field: 'category_name' },
          { heading: 'Image', field: 'attachment' },
        ],
        fields: [
          { type: 'text', name: 'category_name' },
          { type: 'file', name: 'attachment' },
        ],
      },
      {
        name: 'Sub-Category',
        apiBase: '/mstSubProductCategories',
        getAllRoute: '/getAllmstSubProductCategories',
        addRoute: '/savemstSubProductCategories',
        updateRoute: '/updatemstSubProductCategories',
        deleteRoute: '/bulkDelete',
        idKey: 'spc_id',
        columnList: [
          { heading: 'Category', field: 'category.category_name' },
          { heading: 'Sub Category Name', field: 'sub_category_name' },
          { heading: 'Image', field: 'attachment' },
        ],
        fields: [
          { type: 'select', name: 'category', value: 'product_category_id', optionLabel: 'category_name', listField: 'pc_id' },
          { type: 'text', name: 'sub_category_name' },
          { type: 'file', name: 'attachment' },
        ],
      },
      {
        name: 'Grade',
        apiBase: '/mstGrade',
        getAllRoute: '/getAllmstGrade  ',
        addRoute: '/savemstGrade',
        updateRoute: '/updatemstGrade',
        deleteRoute: '/bulkDelete',
        idKey: 'grade_id',
        columnList: [
          { heading: 'Grade Name', field: 'grade_name' },
          { heading: 'Grade Code', field: 'grade_code' },
        ],
        fields: [
          { type: 'text', name: 'grade_name' },
          { type: 'text', name: 'grade_code' },
          { type: 'checkbox', name: 'is_external' },
        ],
      },
      {
        name: 'Shape',
        apiBase: '/mstShape',
        getAllRoute: '/getAllmstShape',
        addRoute: '/savemstShape',
        updateRoute: '/updatemstShape',
        deleteRoute: '/bulkDelete',
        idKey: 'shape_id',
        columnList: [
          { heading: 'Shape Name', field: 'shape_name' },
          { heading: 'Shape Code', field: 'shape_code' },
        ],
        fields: [
          { type: 'text', name: 'shape_name' },
          { type: 'text', name: 'shape_code' },
          { type: 'checkbox', name: 'is_external' },
        ],
      },
      {
        name: 'Process Route',
        apiBase: '/mstProcessRoute',
        getAllRoute: '/getAllmstProcessRoute',
        addRoute: '/savemstProcessRoute',
        updateRoute: '/updatemstProcessRoute',
        deleteRoute: '/bulkDelete',
        idKey: 'pr_id',
        columnList: [
          { heading: 'Process Route Name', field: 'pr_name' },
          { heading: 'process Route Code', field: 'pr_code' },
        ],
        fields: [
          { type: 'text', name: 'pr_name' },
          { type: 'text', name: 'pr_code' },
          { type: 'checkbox', name: 'is_external' },
        ],
      },
      {
        name: 'Heat Treat',
        apiBase: '/mstHeatTreat',
        getAllRoute: '/getAllmstHeatTreat',
        addRoute: '/savemstHeatTreat',
        updateRoute: '/updatemstHeatTreat',
        deleteRoute: '/bulkDelete',
        idKey: 'ht_id',
        columnList: [
          { heading: 'Heat Treat name', field: 'heat_treat_name' },
          { heading: 'Heat Treat Code', field: 'ht_code' },
        ],
        fields: [
          { type: 'text', name: 'heat_treat_name' },
          { type: 'text', name: 'ht_code' },
          { type: 'checkbox', name: 'is_external' },
        ],
      },
      {
        name: 'Surface Condition',
        apiBase: '/mstSurfaceConditions',
        getAllRoute: '/getAllmstSurfaceConditions',
        addRoute: '/savemstSurfaceConditions',
        updateRoute: '/updatemstSurfaceConditions',
        deleteRoute: '/bulkDelete',
        idKey: 'sc_id',
        columnList: [
          { heading: 'Surface Condition', field: 'surface_condition' },
          { heading: 'Surface Condition Code', field: 'sc_code' },
        ],
        fields: [
          { type: 'text', name: 'surface_condition' },
          { type: 'text', name: 'sc_code' },
          { type: 'checkbox', name: 'is_external' },
        ],
      },
      {
        name: 'Size',
        apiBase: '/mstSizes',
        getAllRoute: '/getAllmstSizes',
        addRoute: '/savemstSizes',
        updateRoute: '/updatemstSizes',
        deleteRoute: '/bulkDelete',
        idKey: 'size_id',
        columnList: [
          { heading: 'Sub-Category', field: 'sub_category.sub_category_name' },
          { heading: 'Size Name', field: 'size_name' },
          { heading: 'Size Code', field: 'size_code' },
        ],
        fields: [
          { type: 'select', name: 'sub_category', value: 'spc_id', optionLabel: 'sub_category_name', listField: 'spc_id' },
          { type: 'text', name: 'size_name' },
          { type: 'text', name: 'size_code' },
          { type: 'checkbox', name: 'is_external' },
        ],
      },
      {
        name: 'Mill',
        apiBase: '/mstMills',
        getAllRoute: '/getAllmstMills',
        addRoute: '/savemstMills',
        updateRoute: '/updatemstMills',
        deleteRoute: '/bulkDelete',
        idKey: 'mill_id',
        columnList: [
          { heading: 'Mill Name', field: 'mill_name' },
          { heading: 'Mill Code', field: 'mill_code' },
        ],
        fields: [
          { type: 'text', name: 'mill_name' },
          { type: 'text', name: 'mill_code' },
          { type: 'checkbox', name: 'is_external' },
        ],
      },
    ],
    []
  );

  const limit = 10;
  const [masterDataList, setMasterDataList] = useState([]);
  const [selectedMaster, setSelectedMaster] = useState(productMasterList[0]);
  const [isSelectMasterDisabled, setIsSelectMasterDisabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectedRows, setselectedRows] = useState([]);
  const [selcetList, setSelcetList] = useState([]);
  const [isShowExternalCheckbox, setIsShowExternalCheckbox] = useState([]);
  const [attachFileName, setAttachFileName] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  // const userData = JSON.parse(localStorage.getItem('userDetails'));
  const handleSelectMaster = (master) => {
    setSelectedMaster(master);
    setAttachFileName([]);
  };
  useEffect(() => {
    fetchMasterList();
  }, [currentPage, selectedMaster]);
  const fetchMasterList = async () => {
    try {
      const urlPath = `${selectedMaster.apiBase + selectedMaster.getAllRoute}`;
      const res = await postData(urlPath, { page: currentPage, limit, filters: {} });
      if (res?.data?.data) {
        setTotalPages(res.data.data.totalPages);
        const dataList = res.data.data?.data ? res.data.data.data : res.data.data?.products ? res.data.data.products : [];
        setMasterDataList(dataList);
      } else {
        setMasterDataList([]);
        dispatch(showSnackbar({ message: res.response.data.message, severity: 'error' }));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message ? error.response?.data?.message : error.message;
      dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
      setMasterDataList([]);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let res;
      if ((selectedMaster.name == 'Category' || selectedMaster.name == 'Sub-Category') && attachFileName) {
        let payload = new FormData();
        payload.append('is_visible', formData['is_visible'] || true);
        payload.append('is_external', formData['is_external'] || false);
        payload.append('created_by', userData.user.user_id);
        payload.append('is_visible', userData.user.user_id);
        payload.append('attachment', formData.attachment);
        payload.append('file', attachFileName);
        if (selectedMaster.name == 'Category') {
          payload.append('category_name', formData['category_name']);
          payload.append('pc_id', formData['pc_id']);
        } else {
          payload.append('product_category_id', formData['product_category_id']);
          payload.append('sub_category_name', formData['sub_category_name']);
          payload.append('spc_id', formData['spc_id']);
        }
        res = formData[selectedMaster.idKey]
          ? await postFileData(`${selectedMaster.apiBase + selectedMaster.updateRoute}`, payload, {}, userData.token)
          : await postFileData(`${selectedMaster.apiBase + selectedMaster.addRoute}`, payload, {}, userData.token);
      } else {
        formData['is_visible'] = formData['is_visible'] || true;
        formData['is_external'] = formData['is_external'] || false;
        formData['created_by'] = userData.user.user_id;
        formData['modified_by'] = userData.user.user_id;
        res = formData[selectedMaster.idKey]
          ? await postData(`${selectedMaster.apiBase + selectedMaster.updateRoute}`, formData, {}, userData.token)
          : await postData(`${selectedMaster.apiBase + selectedMaster.addRoute}`, formData, {}, userData.token);
      }
      if (res?.data?.status) {
        dispatch(showSnackbar({ message: `${formData[selectedMaster.idKey] ? 'updated' : 'added'} successfully`, severity: 'success' }));

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        await fetchMasterList();
        setAttachFileName([]);
        handleClose();
        setFormData({});
      } else {
        // setFormData({});
        // setAttachFileName([]);
        dispatch(showSnackbar({ message: res.response.data.message, severity: 'error' }));
      }
    } catch (error) {
      dispatch(showSnackbar({ message: error?.response?.data?.message || error.message, severity: 'error' }));
      // setFormData({});
      // setAttachFileName([]);
    } finally {
      setLoading(false);
    }
  };
  const handleOpen = async () => {
    setOpen(true);
    let formData = {};
    selectedMaster.fields.forEach((field) => {
      formData[field.name] = '';
    });
    if (selectedMaster.name == 'Sub-Category') {
      await fetchSelectList(`/mstProductCategories/getAllmstProductCategoriesv1/`);
    }
    if (selectedMaster.name == 'Size') {
      await fetchSelectList(`/mstSubProductCategories/getAllmstSubProductCategoriesv1/`);
    }
    setFormData(formData);
    setIsShowExternalCheckbox(false);
  };
  const handleClose = () => {
    setFormData({});
    // setPriceData(initFormData);
    setIsEdit(false);
    setIsSelectMasterDisabled(false);
    setIsShowExternalCheckbox(false);
    setOpen(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleCheckbox = () => {
    setFormData((prevData) => ({
      ...prevData,
      ['is_external']: !formData.is_external,
    }));
  };
  const handleBulkDelete = async () => {
    if (selectedRows.length === 0) {
      dispatch(showSnackbar({ message: 'No items selected for deletion', severity: 'error' }));
      return;
    }

    if (window.confirm('Are you sure you want to delete selected items?')) {
      try {
        const urlPath = `${selectedMaster.apiBase + selectedMaster.deleteRoute}`;
        await postData(urlPath, { ids: selectedRows }, {}, userData.token);
        dispatch(showSnackbar({ message: 'Selected items deleted successfully', severity: 'success' }));
        setTimeout(() => {
          fetchMasterList();
          setselectedRows([]);
        }, 600);
      } catch (error) {
        dispatch(showSnackbar({ message: error?.response?.data?.message || error.message, severity: 'error' }));
      }
    }
  };
  const handleSelect = (value) => {
    if (selectedMaster.name == 'Sub-Category') {
      setFormData((prevData) => ({
        ...prevData,
        ['product_category_id']: value.pc_id,
      }));
    }
    if (selectedMaster.name == 'Size') {
      setFormData((prevData) => ({
        ...prevData,
        ['spc_id']: value.spc_id,
      }));
    }
  };
  const fetchSelectList = async (urlPath) => {
    await getData(urlPath)
      .then((res) => {
        if (res.data.data) {
          setSelcetList(res.data?.data || []);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getNestedFieldValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };
  const handleSelectAll = () => {
    if (selectedRows.length === masterDataList.length) {
      setselectedRows([]);
    } else {
      setselectedRows(masterDataList.map((row) => row[selectedMaster.idKey]));
    }
  };
  const handleSelectRow = (id) => {
    setselectedRows((prev) => (prev.includes(id) ? prev.filter((selectedId) => selectedId !== id) : [...prev, id]));
  };
  const editRow = async (row) => {
    setFormData((prev) => ({ ...prev, ...row }));
    setIsSelectMasterDisabled(true);
    if (selectedMaster.name == 'Sub-Category') {
      await fetchSelectList(`/mstProductCategories/getAllmstProductCategoriesv1/`);
    }
    if (selectedMaster.name == 'Size') {
      await fetchSelectList(`/mstSubProductCategories/getAllmstSubProductCategoriesv1/`);
    }
    setIsShowExternalCheckbox(row?.is_external ? true : false);
    setIsEdit(true);
    setOpen(true);
  };
  const handleAttachFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        dispatch(showSnackbar({ message: 'Please select a valid image file', severity: 'warning' }));
        event.target.value = '';
      } else {
        setAttachFileName(file);
        setFormData((prev) => ({ ...prev, attachment: '' }));
        // setMasterDetails((prev) => ({ ...prev, attachment: file }));
        event.target.value = '';
      }
    }
  };
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setSelectedImage(null);
    setIsPopupOpen(false);
  };
  return (
    <>
      <Heading20Semi text="Dimensions Master" />
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <FormControl sx={{ m: 1, width: 550, display: 'flex', alignItems: 'center' }}>
          <InputLabel id="select-master-label">Select Master</InputLabel>
          <Select
            labelId="select-master-label"
            fullWidth
            value={selectedMaster.name}
            label={'Select Master'}
            disabled={isSelectMasterDisabled}
          >
            {productMasterList.map((item, i) => (
              <MenuItem value={item.name} key={i} onClick={() => handleSelectMaster(item)}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
        <CustomBtnBox onClick={handleOpen}>
          <img src={Add} alt="add" style={{ height: '22px', width: '22px' }} />
          <Typography sx={{ fontSize: '10px' }}>ADD</Typography>
        </CustomBtnBox>
        <CustomBtnBox onClick={handleBulkDelete}>
          <img src={Delete} alt="delete" style={{ height: '21px', width: '20px' }} />
          <Typography sx={{ fontSize: '10px' }}>DELETE</Typography>
        </CustomBtnBox>
      </Box>
      <Box sx={{ mt: 1 }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" sx={{ minWidth: 650, border: '1px solid #0093D3' }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#0093D3' }} key={'rowHeading'}>
                <TableCell padding="checkbox" key={'selectAll'}>
                  <Checkbox
                    key={'checkbox'}
                    sx={{ color: '#fff' }}
                    indeterminate={selectedRows.length > 0 && selectedRows.length < masterDataList.length}
                    checked={masterDataList.length > 0 && selectedRows.length === masterDataList.length}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {selectedMaster.columnList.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{ border: '1px solid #ffffff', fontWeight: 600, textTransform: 'capitalize', color: '#ffffff' }}
                  >
                    {column.heading}
                  </TableCell>
                ))}
                <TableCell key={'edit'} sx={{ border: '1px solid #0093D3', fontWeight: 600, color: '#ffffff' }}>
                  Edit
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {masterDataList.map((row, index) => (
                <TableRow key={row[selectedMaster.idKey]} sx={{ backgroundColor: row?.is_external == true ? '#F4C7C9' : 'none' }}>
                  <TableCell padding="checkbox" sx={{ border: '1px solid #0093D3' }} key={'selectAll' + index}>
                    <Checkbox
                      key={'checkAll' + index}
                      checked={selectedRows.includes(row[selectedMaster.idKey])}
                      onChange={() => handleSelectRow(row[selectedMaster.idKey])}
                    />
                  </TableCell>
                  {selectedMaster.columnList.map((column) => (
                    <TableCell key={column.field} sx={headerCellStyle2}>
                      {column.field === 'attachment' && row[column.field] ? (
                        <Typography
                          sx={{
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleImageClick(row[column.field])}
                        >
                          {row[column.field].split('/').pop()?.split('_').slice(2).join('_')}
                        </Typography>
                      ) : (
                        getNestedFieldValue(row, column.field) || ''
                      )}
                    </TableCell>
                  ))}
                  <TableCell sx={{ border: '1px solid #0093D3' }}>
                    <img
                      key={'edit' + index}
                      style={{ height: '25px', marginLeft: '10px', marginTop: '5px', cursor: 'pointer' }}
                      src={Edit}
                      alt="edit"
                      onClick={() => editRow(row)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      </Box>

      <Modal open={open} onClose={handleClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Paper sx={{ padding: 2, width: 400 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>
            {isEdit ? 'Edit' : 'Add New'} {selectedMaster.name}
          </Typography>
          <form onSubmit={handleSubmit}>
            {selectedMaster.fields.map((field, index) => {
              if (field.type === 'text') {
                return (
                  <FormControl key={index} sx={{ width: '100%' }}>
                    <TextField
                      label={
                        field.name === 'pr_name'
                          ? 'Process Route Name'
                          : field.name === 'pr_code'
                            ? 'Process Route Code'
                            : field.name === 'ht_code'
                              ? 'Heat Treat Code'
                              : field.name === 'sc_code'
                                ? 'Surface Condition Code'
                                : field.name.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
                      } // Dynamic label (e.g., 'Category Name')
                      name={field.name}
                      value={formData[field.name] || ''}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      required
                      sx={{
                        '& .MuiInputLabel-asterisk': {
                          color: 'red',
                        },
                      }}
                    />
                  </FormControl>
                );
              }
              if (field.type === 'select') {
                return (
                  <FormControl key={index} sx={{ mt: 2, width: '100%' }}>
                    <Autocomplete
                      id={`${field.name}-autocomplete`}
                      options={selcetList}
                      getOptionLabel={(option) => option[field.optionLabel] || ''}
                      renderInput={(params) => (
                        <TextField {...params} label={`Select ${field.name.replace(/_/g, ' ')}`} variant="outlined" required />
                      )}
                      value={selcetList.find((item) => item[field.listField] === formData[field.value]) || null}
                      onChange={(event, value) => {
                        if (value) {
                          handleSelect(value);
                        }
                      }}
                      isOptionEqualToValue={(option, value) => option[field.listField] === value[field.value]}
                      fullWidth
                      disabled={isEdit}
                      required
                    />
                  </FormControl>
                );
              }
              if (field.type === 'checkbox' && isShowExternalCheckbox) {
                return (
                  <FormControl key={index} sx={{ width: '100%' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={field.name}
                          checked={formData[field.name] ? false : true}
                          onChange={handleCheckbox}
                          color="primary"
                        />
                      }
                      label={'Verify'}
                    />
                  </FormControl>
                );
              }
              if (field.type === 'file') {
                return (
                  <>
                    <FormControl key={index} sx={{ width: '100%' }}>
                      <input
                        type="file"
                        ref={fileInputRef}
                        accept="image/*" // Accept only image files
                        onChange={(event) => {
                          handleAttachFileChange(event);
                        }}
                        style={{
                          display: 'none',
                        }}
                      />
                      <Box
                        sx={{
                          height: '56px',
                          display: 'flex',
                          mt: 2,
                          alignItems: 'center',
                          gap: '20px',
                          border: '0.5px solid',
                          borderColor: Colors.placeholder,
                          borderRadius: '5px',
                          padding: '0px 10px',
                          background: Colors.select_bg,
                          cursor: 'pointer',
                          '&:hover': {
                            border: '1px solid',
                          },
                        }}
                        onClick={() => {
                          fileInputRef.current.click();
                        }}
                      >
                        <img src={attachmentIcon} alt="attach" />
                        {/* <Typography sx={{ fontFamily: 'poppins-regular', color: Colors.text_413e3e }}>
                          {selectedMaster.attachment ? selectedMaster.attachment.split('/').pop() : attachFileName?.name || 'Attachment'}
                        </Typography> */}
                        <Typography
                          sx={{
                            fontFamily: 'poppins-regular',
                            color: Colors.text_413e3e,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: 'auto',
                          }}
                        >
                          {formData?.attachment
                            ? formData.attachment.split('/').pop()?.split('_').slice(2).join('_')
                            : attachFileName?.name || 'Attachment'}
                        </Typography>
                      </Box>
                    </FormControl>
                  </>
                );
              }
              return null;
            })}

            <Button type="submit" variant="contained" sx={{ mt: 2 }} disabled={loading || formData?.is_external}>
              {loading ? <CircularProgress size={24} /> : 'Submit'}
            </Button>
            <Button variant="outlined" sx={{ mt: 2, ml: 2 }} onClick={handleClose}>
              Cancel
            </Button>
          </form>
        </Paper>
      </Modal>
      {/* Popup for displaying the image */}
      <Dialog open={isPopupOpen} onClose={handleClosePopup} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Image Preview</Typography>
            <IconButton onClick={handleClosePopup}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {selectedImage && (
            <Box sx={{ textAlign: 'center' }}>
              <img src={selectedImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '450px' }} />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DimensionsMaster;
