import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  // MenuItem,
  // Select,
  Switch,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { Paper, IconButton, TextField, List, ListItem, ListItemText, ListItemSecondaryAction, Button, Autocomplete } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import Colors from '../../../../theme/colors/Color';
import { Heading20Semi, Heading26Bold } from '../../../../common-components';
import 'react-quill/dist/quill.snow.css'; // import styles for react-quill
import ReactQuill from 'react-quill';
import { Delete, Search, ThumbUp } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { getData, postData, postFileData, updateData } from '../../../../services/APIService';
import { useDispatch, useSelector } from 'react-redux';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';

const CustomPaper = styled(Paper)(() => ({
  padding: '28px 9px',
}));

const toolbarOptions = [
  [{ font: [] }],
  [{ header: [1, 2, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ direction: 'rtl' }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ['link', 'image'],
  ['clean'],
  ['undo', 'redo'],
];

const EditCommunity = () => {
  const [communityDetails, setCommunityDetails] = useState({
    realm: 'Segment',
    realm_select: 'Market Segment 1',
  });
  const location = useLocation();
  const { state } = location;
  const [postMessage, setPostmessage] = useState({
    subject: '',
    community_id: '',
    message: '',
    attachment: null,
  });
  const [postList, setPostList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [searchMemberList, setSearchMemberList] = useState([]);
  const [membersCount, setMembersCount] = useState(0);
  const [selectMemberList, setSlectMemberList] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const fileInputRef = useRef(null);
  const [attachFileName, setAttachFileName] = useState([]);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const Masters = useSelector((state) => state.masters);
  const userCategory = Masters?.data?.mstUserCategory || [];
  const segments = Masters?.data?.mstMarketSegment || [];
  const mode = state.mode || '';

  console.log('communityDetails', communityDetails);
  console.log('segments', segments);

  const fetchDetailsCommunity = async () => {
    try {
      const res = await getData(`/community/communitydetails/${state.id}`, {}, userData.token);
      if (res && res.data && res.data.status) {
        setCommunityDetails(res.data.data);
      } else {
        navigate('/community');
        dispatch(showSnackbar({ message: res.response?.data?.message, severity: 'error' }));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message ? error.response?.data?.message : error.message;
      dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
    }
  };
  const fetchPostList = async (communityId) => {
    try {
      const res = await getData(`/community/getPosts/${communityId}`, {}, userData.token);
      if (res && res.data && res.data.status) {
        setPostList(res.data.data);
      } else {
        dispatch(showSnackbar({ message: res.response?.data?.message, severity: 'error' }));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message ? error.response?.data?.message : error.message;
      dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
    }
  };
  const fetchMemberList = async (communityId) => {
    try {
      const res = await getData(`/community/getMembers/${communityId}`, {}, userData.token);
      if (res && res.data && res.data.status) {
        setMemberList(res.data.data?.rows);
        setSearchMemberList(res.data.data?.rows);
        setMembersCount(res.data.data?.count);
      } else {
        dispatch(showSnackbar({ message: res.response?.data?.message, severity: 'error' }));
      }
    } catch (error) {
      console.log(error);
      // const errorMessage = error.response?.data?.message ? error.response?.data?.message : error.message;
      // dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
    }
  };
  const fetchSelectMemberList = async (communityId) => {
    try {
      const res = await getData(`/community/getMembersList/${communityId}`, {}, userData.token);
      if (res && res.data && res.data.status) {
        setSlectMemberList(res?.data?.data || []);
      } else {
        dispatch(showSnackbar({ message: res.response?.data?.message, severity: 'error' }));
      }
    } catch (error) {
      setSlectMemberList([]);
      const errorMessage = error.response?.data?.message ? error.response?.data?.message : error.message;
      dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
    }
  };
  useEffect(() => {
    fetchDetailsCommunity();
    fetchPostList(state.id);
    fetchMemberList(state.id);
    if (state?.mode != 'view') fetchSelectMemberList(state.id);
    if (state.id) {
      setPostmessage((prev) => ({ ...prev, community_id: state.id }));
    }
  }, []);
  const handleAttachFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        dispatch(showSnackbar({ message: 'Please select a valid image file', severity: 'warning' }));
        event.target.value = '';
      } else {
        setAttachFileName(file);
        event.target.value = '';
        setPostmessage((prev) => ({ ...prev, attachment: file || null }));
      }
    }
  };
  const postSubmit = async (e) => {
    e.preventDefault();
    if (!postMessage.subject) {
      dispatch(showSnackbar({ message: 'Please enter subject', severity: 'warning' }));
      return false;
    }
    if (!postMessage.message) {
      dispatch(showSnackbar({ message: 'Please enter message', severity: 'warning' }));
      return false;
    }
    try {
      let payload = new FormData();
      payload.append('subject', postMessage.subject);
      payload.append('message', postMessage.message);
      payload.append('community_id', postMessage.community_id);
      payload.append('file', postMessage.attachment);
      const res = await postFileData(`/community/addCommunityPost`, payload, {}, userData.token);
      if (res && res.data && res.data.status) {
        dispatch(showSnackbar({ message: 'Post message added succesfully', severity: 'success' }));
        fetchPostList(postMessage.community_id);
        setPostmessage((prev) => ({ ...prev, message: '', subject: '', attachment: '' }));
        setAttachFileName([]);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } else dispatch(showSnackbar({ message: res.response?.data?.message, severity: 'error' }));
    } catch (error) {
      const errorMessage = error.response?.data?.message ? error.response?.data?.message : error.message;
      dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
    }
  };
  const updateCommunity = async (e) => {
    const status = e.target.checked ? 'Active' : 'Inactive';
    setCommunityDetails({ ...communityDetails, status });
    const id = communityDetails.community_id;
    if (window.confirm(`Are you sure you want to ${status == 'Active' ? 'Active' : 'Inactive'} this Community ?`)) {
      try {
        const res = await updateData(`/community/updateCommunity/${id}`, { status }, {}, userData.token);
        if (res && res.data && res.data.status) {
          dispatch(showSnackbar({ message: res.data.message, severity: 'success' }));
          fetchPostList(postMessage.community_id);
        } else dispatch(showSnackbar({ message: res.response?.data?.message, severity: 'error' }));
      } catch (error) {
        const errorMessage = error.response?.data?.message ? error.response?.data?.message : error.message;
        dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
      }
    }
  };
  const handlePublishUnpublish = async (post) => {
    const isPublish = !post.published;
    if (window.confirm(`Are you sure you want to ${isPublish ? 'publish' : 'unpublish'} post?`)) {
      try {
        const postObj = {
          is_publish: isPublish,
        };
        const res = await updateData(`/community/publishUnpublishPost/${post.post_id}`, postObj, {}, userData.token);
        if (res && res.data && res.data.status) {
          dispatch(showSnackbar({ message: res.data.message, severity: 'success' }));
          fetchPostList(postMessage.community_id);
        } else dispatch(showSnackbar({ message: res.response?.data?.message, severity: 'error' }));
      } catch (error) {
        const errorMessage = error.response?.data?.message ? error.response?.data?.message : error.message;
        dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
      }
    }
  };
  const handlePostDelete = async (post) => {
    if (window.confirm('Are you sure you want to delete post?')) {
      try {
        const res = await updateData(`/community/postDelete/${post.post_id}`, {}, {}, userData.token);
        if (res && res.data && res.data.status) {
          dispatch(showSnackbar({ message: res.data.message, severity: 'success' }));
          fetchPostList(postMessage.community_id);
        } else dispatch(showSnackbar({ message: res.response?.data?.message, severity: 'error' }));
      } catch (error) {
        const errorMessage = error.response?.data?.message ? error.response?.data?.message : error.message;
        dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
      }
    }
  };
  const addMember = async (value) => {
    let userId = value?.user_id;
    if (!userId) return;
    // let userId = e.target.value;
    try {
      const payload = {
        community_id: postMessage.community_id,
        user_ids: [userId],
      };
      const res = await postData(`/community/addMembers`, payload, {}, userData.token);
      if (res && res.data && res.data.status) {
        dispatch(showSnackbar({ message: res.data.message, severity: 'success' }));
        fetchMemberList(postMessage.community_id);
        const selectMembers = selectMemberList.filter((member) => member.user_id != userId);
        setSlectMemberList(selectMembers);
        setSelectedValue(null);
      } else dispatch(showSnackbar({ message: res.response?.data?.message, severity: 'error' }));
    } catch (error) {
      const errorMessage = error.response?.data?.message ? error.response?.data?.message : error.message;
      dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
    }
  };
  const handleAutoAddMember = async () => {
    if (window.confirm('Are you sure you want to auto add members?')) {
      try {
        const payload = {
          community_id: postMessage.community_id,
          user_type: communityDetails.user_type,
          realm: communityDetails.realm,
          realm_select: communityDetails.realm_select,
        };
        setloading(true);
        const res = await postData(`/community/addAutoMembers`, payload, {}, userData.token);
        if (res && res.data && res.data.status) {
          dispatch(showSnackbar({ message: res.data.message, severity: 'success' }));
          fetchMemberList(postMessage.community_id);
          fetchSelectMemberList(postMessage.community_id);
          setloading(false);
        } else {
          dispatch(showSnackbar({ message: res.response?.data?.message, severity: 'error' }));
          setloading(false);
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message ? error.response?.data?.message : error.message;
        dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
        setloading(false);
      }
    }
  };
  const handleChange = (e) => {
    setPostmessage((prev) => ({ ...prev, message: e }));
  };
  const searchmembers = (e) => {
    const v = e.target.value.toLowerCase();
    setSearchMemberList(memberList.filter((member) => (member?.member?.name ?? member?.member?.email)?.toLowerCase().includes(v)));
  };
  const removeMember = async (member) => {
    if (window.confirm('Are you sure you want to remove member?')) {
      try {
        const res = await updateData(`/community/removeMember/${member.cm_id}`, {}, {}, userData.token);
        if (res && res.data && res.data.status) {
          dispatch(showSnackbar({ message: res.data.message, severity: 'success' }));
          fetchMemberList(postMessage.community_id);
          fetchSelectMemberList(postMessage.community_id);
        } else dispatch(showSnackbar({ message: res.response?.data?.message, severity: 'error' }));
      } catch (error) {
        const errorMessage = error.response?.data?.message ? error.response?.data?.message : error.message;
        dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
      }
    }
  };
  return (
    <>
      <Heading26Bold text={'Create Community'} />
      <Box
        style={{
          backgroundColor: '#b3faff',
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: '20px',
          paddingRight: '10px',
          alignItems: 'center',
        }}
        sx={{
          height: {
            lg: '60px', // large screens
            xl: '7vh', // extra-large screens
          },
          width: {
            lg: '97%', // large screens
            xl: '98%', // extra-large screens
          },
        }}
      >
        <Typography
          sx={{
            fontSize: {
              lg: '14px',
              xl: '16px',
            },
          }}
        >
          <strong>Community Number:</strong> {communityDetails.community_no}
        </Typography>
        <Typography
          sx={{
            fontSize: {
              lg: '14px',
              xl: '16px',
            },
          }}
        >
          <strong>Date Of Creation:</strong>
          {new Date(communityDetails.createdAt).toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
          })}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'white',
            width: '50%',
            height: '30px',

            padding: {
              xs: '120px',
              lg: '5px',
              xl: '5px',
            },
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              style={{ fontWeight: 'bold' }}
              sx={{
                fontSize: {
                  lg: '14px',
                  xl: '16px',
                },
              }}
            >
              <span>
                <span style={{ color: '#9C9A9A' }}>Realm</span>
                <span style={{ color: '#9C9A9A' }}> : </span>
                <span style={{ color: 'red' }}> * </span>
              </span>
            </Typography>
            <select
              style={{
                minWidth: '160px',
                padding: '8px',
                fontSize: '14px',
                borderRadius: '4px',
                border: 'none',
              }}
              value={communityDetails?.realm || ''}
              disabled
            >
              <option value="" disabled>
                Select Realm
              </option>
              <option value="Segment">Segment</option>
              <option value="Category">Category</option>
            </select>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              style={{ margin: '4px', fontWeight: 'bold' }}
              sx={{
                fontSize: {
                  lg: '14px',
                  xl: '16px',
                  flex: 'justify-content-end',
                },
              }}
            >
              <span>
                <span style={{ color: '#9C9A9A' }}>Select</span>
                <span style={{ color: '#9C9A9A' }}> : </span>
                <span style={{ color: 'red' }}> * </span>
              </span>
            </Typography>
            <select
              style={{
                minWidth: '240px',
                padding: '8px',
                fontSize: '14px',
                borderRadius: '4px',
                border: 'none',
              }}
              disabled
            >
              {/*  <option value="" disabled selected>
              {communityDetails.realm === 'Segment'
                ? 'Select Segment'
                : communityDetails.realm === 'Category'
                  ? 'Select Category'
                  : 'Select Realm'}
            </option> */}

              {communityDetails.realm === 'Segment' && (
                <>
                  {segments.map((category) => {
                    return (
                      <option key={category.id} value={category.name || ''}>
                        {category.name}
                      </option>
                    );
                  })}
                </>
              )}
              {communityDetails.realm === 'Category' && (
                <>
                  {userCategory.map((category) => (
                    <option key={category.id} value={category.name || ''}>
                      {category.name}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>
        </Box>
      </Box>
      <Box sx={{ marginTop: '30px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8}>
            <CustomPaper elevation={5} sx={{ borderRadius: '10px', height: { md: '45vh', xs: '40vh' }, padding: '10px 9px 45px' }}>
              <FormLabel>
                <Heading20Semi text={'Post Messages '} />
              </FormLabel>
              <TextField
                variant="standard"
                placeholder="Add A Subject"
                value={postMessage.subject || ''}
                onChange={(e) => setPostmessage((prev) => ({ ...prev, subject: e.target.value }))}
                name="subject"
                size="small"
                fullWidth
                sx={{ marginTop: '10px' }}
                disabled={mode == 'view'}
              />
              <Box mt={1}>
                <Box
                  sx={{
                    height: {
                      xs: '150px', // small screens
                      lg: '138px', // large screens
                      xl: '29vh', // extra-large screens
                    },
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    borderRadius: '4px',
                    overflow: 'hidden', // Ensure the content fits within the box
                  }}
                >
                  <ReactQuill
                    fullWidth
                    value={postMessage.message}
                    onChange={handleChange}
                    name="message"
                    placeholder="Type A Message"
                    backgroundColor="red"
                    modules={{ toolbar: toolbarOptions }}
                    style={{ height: '100%', pointerEvents: mode == 'view' ? 'none' : 'auto' }}
                  />
                </Box>
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    top: '10px',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Divider
                    sx={{
                      width: '100%',
                      borderBottom: '2px solid',
                      borderColor: Colors.primary,
                      height: {
                        xl: '2vh',
                      },
                    }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Box sx={{ display: 'flex' }}>
                      <input
                        type="file"
                        ref={fileInputRef}
                        accept="image/*" // Accept only image files
                        onChange={(event) => {
                          handleAttachFileChange(event);
                        }}
                        style={{
                          display: 'none',
                        }}
                      />
                      <IconButton component="span" sx={{ fontWeight: 'bold' }} disabled={mode == 'view'}>
                        <AttachFileIcon
                          sx={{ fontWeight: 'bold', color: Colors.text_black }}
                          onClick={() => {
                            fileInputRef.current.click();
                          }}
                        />
                      </IconButton>
                      <Typography
                        sx={{
                          padding: '10px 10px 0px',
                          fontSize: {
                            lg: '14px',
                            xl: '16px',
                          },
                        }}
                      >
                        {attachFileName?.name}
                      </Typography>
                    </Box>
                    <IconButton sx={{ color: Colors.primary }} disabled={mode == 'view'}>
                      <SendIcon onClick={postSubmit} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </CustomPaper>
          </Grid>

          <Grid item xs={12} lgm={4}>
            <CustomPaper
              elevation={5}
              sx={{ borderRadius: '10px', height: { md: '45vh' }, padding: '10px 9px 45px', marginBottom: '15px' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <FormLabel>
                  <Heading20Semi text={'Community Details '} />
                </FormLabel>
                {/* <Switch label={'Switch'} color={'success'} size="medium" checked={communityDetails.status == 'Active' ? 'true' : 'false'} /> */}
                <FormControlLabel
                  label="Status"
                  control={
                    <Switch
                      color="success"
                      size="medium"
                      checked={communityDetails.status === 'Active'} // Ensure this is a boolean
                      // onChange={(e) => setCommunityDetails({ ...communityDetails, status: e.target.checked ? 'Active' : 'Inactive' })}
                      onChange={updateCommunity}
                      disabled={false}
                    />
                  }
                />
              </Box>
              <Box sx={{ marginTop: 1 }}>
                <label>Community Name</label>
                <TextField
                  sx={{
                    height: { md: '5vh' },
                    '& .MuiInputBase-root': {
                      height: { xl: '10vh' },
                      alignItems: 'flex-start',
                    },
                  }}
                  placeholder="Name Of Community"
                  variant="outlined"
                  fullWidth
                  size="small"
                  margin="dense"
                  value={communityDetails.community_name}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                />
              </Box>

              <Box sx={{ marginTop: 1 }}>
                <label>User Type</label>
                <TextField
                  sx={{
                    height: { md: '5vh' },
                    '& .MuiInputBase-root': {
                      height: { xl: '10vh' },
                      alignItems: 'flex-start',
                    },
                  }}
                  placeholder="Name of Community"
                  variant="outlined"
                  fullWidth
                  size="small"
                  margin="dense"
                  value={communityDetails.user_type} // Set the value to community_name
                  InputProps={{
                    readOnly: true, // Make the field read-only to prevent editing
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                />
              </Box>
              <Box sx={{ marginTop: 1 }}>
                <label>Community Description</label>
                <TextField
                  sx={{
                    height: { md: '5vh' },
                    '& .MuiInputBase-root': {
                      height: { lg: '17vh', xl: '20vh' },
                      alignItems: 'flex-start',
                    },
                  }}
                  placeholder="Name of Community"
                  variant="outlined"
                  fullWidth
                  size="small"
                  margin="dense"
                  value={communityDetails.description} // Set the value to community_name
                  InputProps={{
                    readOnly: true, // Make the field read-only to prevent editing
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  multiline
                  rows={5}
                />
              </Box>
            </CustomPaper>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} lg={8}>
            <CustomPaper elevation={5} sx={{ height: '65vh' }}>
              <FormControl>
                <FormLabel>
                  <Heading20Semi text="Previous Messages" />
                </FormLabel>
              </FormControl>
              <Paper
                sx={{
                  flexGrow: 1,
                  overflowY: 'auto',
                  maxHeight: '62vh',
                  boxShadow: 'none !important',
                  '&::-webkit-scrollbar': {
                    width: '12px',
                    height: '11px',
                    cursor: 'pointer',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: Colors.secondary1,
                    borderRadius: '6px',
                    border: '3px solid transparent',
                    backgroundClip: 'padding-box',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: Colors.primary,
                  },
                }}
              >
                {postList?.map((post) => (
                  <Paper
                    key={post.post_id} // Use post_id as a unique key
                    elevation={3}
                    style={{
                      margin: '10px 0',
                      padding: '10px',
                      borderBottom: '1px solid #ccc',
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h4>Subject : {post.subject}</h4> {/* Display post subject */}
                      <p>
                        <strong>Posted On:</strong>{' '}
                        {post?.createdAt
                          ? new Date(post.createdAt).toLocaleString('en-GB', {
                              day: '2-digit',
                              month: '2-digit',
                              year: '2-digit',
                            })
                          : ''}
                      </p>
                    </div>
                    <Divider
                      sx={{
                        width: '100%',
                        borderBottom: '2px solid #ccc',
                        borderColor: Colors.primary,
                      }}
                    />
                    <p dangerouslySetInnerHTML={{ __html: post.message }} />
                    {post?.attachment && <img src={post?.attachment} alt="image" srcSet="" width={100} height={100} />}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <IconButton>
                          <ThumbUp />
                        </IconButton>
                        <Typography variant="span" style={{ marginBottom: '-100px!important', fontSize: 16 }}>
                          {post.likes || 0}
                        </Typography>
                      </div>
                      <div>
                        <Button
                          disabled={mode == 'view'}
                          variant="text"
                          style={{ color: Colors.primary, textTransform: 'capitalize', textDecoration: 'underline' }}
                          onClick={() => handlePublishUnpublish(post)}
                        >
                          {post.published ? 'Unpublish' : 'Publish'}
                        </Button>
                        <IconButton disabled={mode == 'view'}>
                          <Delete onClick={() => handlePostDelete(post)} />
                        </IconButton>
                      </div>
                    </div>
                  </Paper>
                ))}
                {postList?.length == 0 && (
                  <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '55vh' }}>
                    <Typography variant="body1" align="center" style={{ marginTop: '10px' }}>
                      No previous messages to show
                    </Typography>
                  </Box>
                )}
              </Paper>
            </CustomPaper>
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomPaper elevation={5}>
              <FormControl sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'initial !important' }}>
                <FormLabel>
                  <Heading20Semi text="Add/Remove Members" />
                  <label style={{ fontSize: '13px' }}>Number Of Members: {membersCount}</label>
                </FormLabel>
                <Button
                  disabled={mode == 'view' || loading ? true : false}
                  onClick={handleAutoAddMember}
                  sx={{ height: 40, borderRadius: 10, fontSize: 13, width: '210px' }}
                  variant="contained"
                  color="primary"
                >
                  {loading ? <CircularProgress size={24} /> : 'Auto Add Members'}
                </Button>
              </FormControl>
              <Box sx={{ marginTop: 2 }}>
                <FormControl
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  size="small"
                  sx={{
                    mb: 2,
                    height: { md: '5vh' },
                    '& .MuiInputBase-root': {
                      height: { lg: '7vh' },
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  {/* <label>Add New Member</label> */}
                  <Autocomplete
                    disablePortal
                    value={selectedValue}
                    options={selectMemberList || []}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={(e, value) => {
                      if (value) {
                        setSelectedValue(value);
                        addMember(value);
                      }
                    }}
                    disableClearable
                    disabled={mode === 'view'}
                    renderInput={(params) => <TextField {...params} label="Add New Member" sx={{ height: '40px' }} />}
                    isOptionEqualToValue={(option, value) => option.user_id === value?.user_id}
                    renderOption={(props, option) => (
                      <li {...props} key={option.user_id}>
                        {option.name ?? option.email}
                      </li>
                    )}
                    noOptionsText="No Member found"
                  />
                </FormControl>
              </Box>
              <Box sx={{ marginTop: 3 }}>
                <label>List Of Members</label>
                <TextField
                  placeholder="Search Members"
                  variant="outlined"
                  size="small"
                  fullWidth
                  style={{ marginTop: '10px' }}
                  onChange={searchmembers}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  disabled={mode == 'view'}
                />
              </Box>
              <List
                sx={{
                  flexGrow: 1,
                  overflowY: 'auto',
                  maxHeight: '37vh',
                  pointerEvents: mode == 'view' ? 'none' : 'auto',
                  '&::-webkit-scrollbar': {
                    width: '12px',
                    height: '11px',
                    cursor: 'pointer',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: Colors.secondary1,
                    borderRadius: '6px',
                    border: '3px solid transparent',
                    backgroundClip: 'padding-box',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: Colors.primary,
                  },
                }}
              >
                {searchMemberList.map((member, index) => {
                  console.log('Member', member);
                  return (
                    <ListItem key={index}>
                      <ListItemText primary={(member?.member?.name ?? member?.member?.email) || ''} />
                      <ListItemSecondaryAction>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => removeMember(member)}
                          sx={{ textTransform: 'capitalize', borderRadius: '15px', lineHeight: 1.4 }}
                        >
                          Remove
                        </Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            </CustomPaper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EditCommunity;
